import { editRule } from 'admin/api/service/rule/editRule';
import { CustomError } from 'admin/error/CustomError';
import { ServiceRuleFormType } from 'admin/types/service/rule';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

export const useEditRule = () => {
  const toast = useCustomToast();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: ServiceRuleFormType) => editRule(data),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '規約の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: '規約を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
