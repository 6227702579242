// ----- fetch関連 -----
const GET_ID_POOL_CONSUMERS = {
  ERROR:
    '申し訳ございません。ユーザープール一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'ユーザープール一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_DETAIL = {
  ERROR:
    '申し訳ございません。ユーザープール詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'ユーザープール詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_ATTRIBUTE = {
  ERROR:
    '申し訳ございません。組織レベル属性一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '組織レベル属性一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_ATTRIBUTE_DETAIL = {
  ERROR:
    '申し訳ございません。組織レベル属性詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '組織レベル属性詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUPS = {
  ERROR:
    '申し訳ございません。属性グループ一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '属性グループ一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUP_DETAIL = {
  ERROR:
    '申し訳ございません。属性グループ細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '属性グループ詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_ATTRIBUTE_AND_GROUPS = {
  ERROR:
    '申し訳ございません。組織レベル属性・属性グループ一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '組織レベル属性・属性グループ一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_LOGS = {
  ERROR:
    '申し訳ございません。プライバシーポリシー更新履歴情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'プライバシーポリシー更新履歴が存在しません。既に削除された可能性があります。',
} as const;
const GET_RULES = {
  ERROR:
    '申し訳ございません。組織規約一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '組織規約一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_RULE = {
  ERROR:
    '申し訳ございません。組織レベル規約設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '組織レベル規約設定が存在しません。既に削除された可能性があります。',
} as const;
const GET_ID_POOL_CONSUMER_USER_INFO = {
  ERROR:
    '申し訳ございません。ユーザー設定詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'ユーザー設定詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_MASTER_SETTINGS = {
  ERROR:
    '申し訳ございません。選択肢マスタ一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '選択肢マスタ一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_MASTER_SETTING = {
  ERROR:
    '申し訳ございません。選択肢マスタ情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '選択肢マスタが存在しません。既に削除された可能性があります。',
} as const;
const GET_CODE_SETTINGS = {
  ERROR:
    '申し訳ございません。コード一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'コード一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_CODE_SETTING = {
  ERROR:
    '申し訳ございません。コード情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'コードが存在しません。既に削除された可能性があります。',
} as const;
const GET_MAIL_COMMON_SETTING = {
  ERROR:
    '申し訳ございません。メール共通設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'メール共通設定詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_MAILS = {
  ERROR:
    '申し訳ございません。メール設定一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'メール設定一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_MAIL = {
  ERROR:
    '申し訳ございません。メール設定詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'メール設定詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_MAILWALLS = {
  ERROR:
    '申し訳ございません。メール送信内容一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'メール送信内容一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_MAILWALL = {
  ERROR:
    '申し訳ございません。メール送信内容詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'メール送信内容詳細が存在しません。既に削除された可能性があります。',
} as const;
const GET_FORM_GROUP = {
  ERROR:
    '申し訳ございません。フォーム設問グループ設定一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'フォーム設問グループ設定一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_FORM_GROUP_DETAIL = {
  ERROR:
    '申し訳ございません。フォーム設問グループの取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'フォーム設問グループが存在しません。既に削除された可能性があります。',
} as const;

// ----- Mutate関連 -----
const CHANGE_AGREE_RULE = {
  ERROR: '申し訳ございません。現バージョンへの同意に失敗しました。',
  FAILED:
    '現バージョンへの同意に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_RULE = {
  ERROR: '申し訳ございません。組織レベル規約の作成に失敗しました。',
  FAILED:
    '組織レベル規約の作成に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_RULE = {
  ERROR: '申し訳ございません。組織レベル規約の更新に失敗しました。',
  FAILED:
    '組織レベル規約の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_RULE_STATUS = {
  ERROR: '申し訳ございません。組織レベル規約のステータス更新に失敗しました。',
  FAILED:
    '組織レベル規約のステータス更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_MASTER_SETTING = {
  ERROR: '申し訳ございません。選択肢マスタの登録に失敗しました。',
  FAILED:
    '選択肢マスタの登録に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_MASTER_SETTING = {
  ERROR: '申し訳ございません。選択肢マスタの変更に失敗しました。',
  FAILED:
    '選択肢マスタの変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_CODE_SETTING = {
  ERROR: '申し訳ございません。コードの登録に失敗しました。',
  FAILED:
    'コードの登録に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_CODE_SETTING = {
  ERROR: '申し訳ございません。コードの変更に失敗しました。',
  FAILED:
    'コードの変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const DELETE_CODE_SETTING = {
  ERROR: '申し訳ございません。コードの削除に失敗しました。',
  FAILED:
    'コードの削除に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_ID_POOL_CONSUMER_ATTRIBUTE = {
  ERROR: '申し訳ございません。組織レベル属性の作成に失敗しました。',
  FAILED:
    '組織レベル属性の作成に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_ID_POOL_CONSUMER_ATTRIBUTE = {
  ERROR: '申し訳ございません。組織レベル属性の更新に失敗しました。',
  FAILED:
    '組織レベル属性の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const ATTRIBUTE_STATUS_CHANGE = {
  ERROR: '申し訳ございません。ステータスの変更に失敗しました。',
  FAILED:
    'ステータスの変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const ATTRIBUTE_AUTO_APPEND_CHANGE = {
  ERROR: '申し訳ございません。「全テナント割当」の変更に失敗しました。',
  FAILED:
    '「全テナント割当」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const ATTRIBUTE_FORM_ATTR_CHANGE = {
  ERROR: '申し訳ございません。「全フォーム利用」の変更に失敗しました。',
  FAILED:
    '「全フォーム利用」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_ATTRIBUTE_GROUP = {
  ERROR: '申し訳ございません。属性グループの作成に失敗しました。',
  FAILED:
    '属性グループの作成に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_ATTRIBUTE_GROUP = {
  ERROR: '申し訳ございません。属性グループの更新に失敗しました。',
  FAILED:
    '属性グループの更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const ATTRIBUTE_GROUP_STATUS_CHANGE = {
  ERROR: '申し訳ございません。属性グループのステータスの変更に失敗しました。',
  FAILED:
    '属性グループのステータスの変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const ATTRIBUTE_GROUP_AUTO_APPEND_CHANGE = {
  ERROR:
    '申し訳ございません。属性グループの「全テナント割当」の変更に失敗しました。',
  FAILED:
    '属性グループの「全テナント割当」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const ATTRIBUTE_GROUP_FORM_ATTR_CHANGE = {
  ERROR:
    '申し訳ございません。属性グループの「全フォーム利用」の変更に失敗しました。',
  FAILED:
    '属性グループの「全フォーム利用」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_MAIL_COMMON_SETTING = {
  ERROR: '申し訳ございません。メール共通設定の更新に失敗しました。',
  FAILED:
    'メール共通設定の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_MAIL = {
  ERROR: '申し訳ございません。メール設定の更新に失敗しました。',
  FAILED:
    'メール設定の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_MAILWALL = {
  ERROR: '申し訳ございません。メール画面の作成に失敗しました。',
  FAILED:
    'メール画面の作成に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_MAILWALL = {
  ERROR: '申し訳ございません。メール画面の更新に失敗しました。',
  FAILED:
    'メール画面の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const SEND_TESTMAIL = {
  ERROR: '申し訳ございません。テストメールの送信に失敗しました。',
  FAILED:
    'テストメールの送信に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_COMMON_USE = {
  ERROR:
    '申し訳ございません。すべてのサービス共通で利用する設定の変更に失敗しました。',
  FAILED:
    'すべてのサービス共通で利用する設定の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_USER_POOL = {
  ERROR: '申し訳ございません。ユーザープールの登録に失敗しました。',
  FAILED:
    'ユーザープールの登録に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_USER_POOL = {
  ERROR: '申し訳ございません。ユーザープールの変更に失敗しました。',
  FAILED:
    'ユーザープールの変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const SETTING_ID_POOL_CONSUMER_USER_INFO = {
  ERROR: '申し訳ございません。ユーザー情報取得設定の更新に失敗しました。',
  FAILED:
    'ユーザー情報取得設定の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CREATE_FORM_GROUP_DETAIL = {
  ERROR: '申し訳ございません。フォーム設問グループの登録に失敗しました。',
  FAILED:
    'フォーム設問グループの登録に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const EDIT_FORM_GROUP_DETAIL = {
  ERROR: '申し訳ございません。フォーム設問グループの更新に失敗しました。',
  FAILED:
    'フォーム設問グループの更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const FORM_GROUP_STATUS = {
  ERROR:
    '申し訳ございません。フォーム設問グループのステータスの更新に失敗しました。',
  FAILED:
    'フォーム設問グループのステータスの更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const USER_POOL = {
  USER_POOL: {
    GET_ID_POOL_CONSUMERS,
    GET_ID_POOL_CONSUMER_DETAIL,
    GET_ID_POOL_CONSUMER_ATTRIBUTE,
    GET_ID_POOL_CONSUMER_ATTRIBUTE_DETAIL,
    GET_LOGS,
    GET_RULES,
    GET_RULE,
    GET_ID_POOL_CONSUMER_USER_INFO,
    GET_MAIL_COMMON_SETTING,
    GET_MAILS,
    GET_MAIL,
    GET_MAILWALLS,
    GET_MAILWALL,
    CHANGE_AGREE_RULE,
    CREATE_RULE,
    EDIT_RULE,
    UPDATE_MAIL_COMMON_SETTING,
    EDIT_MAIL,
    CREATE_MAILWALL,
    EDIT_MAILWALL,
    SEND_TESTMAIL,
    GET_MASTER_SETTINGS,
    GET_MASTER_SETTING,
    CREATE_MASTER_SETTING,
    EDIT_MASTER_SETTING,
    GET_CODE_SETTINGS,
    GET_CODE_SETTING,
    CREATE_CODE_SETTING,
    EDIT_CODE_SETTING,
    DELETE_CODE_SETTING,
    CREATE_ID_POOL_CONSUMER_ATTRIBUTE,
    EDIT_ID_POOL_CONSUMER_ATTRIBUTE,
    CHANGE_RULE_STATUS,
    CHANGE_COMMON_USE,
    CREATE_USER_POOL,
    EDIT_USER_POOL,
    SETTING_ID_POOL_CONSUMER_USER_INFO,
    ATTRIBUTE_STATUS_CHANGE,
    ATTRIBUTE_AUTO_APPEND_CHANGE,
    GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUPS,
    GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUP_DETAIL,
    CREATE_ATTRIBUTE_GROUP,
    EDIT_ATTRIBUTE_GROUP,
    ATTRIBUTE_GROUP_STATUS_CHANGE,
    ATTRIBUTE_GROUP_AUTO_APPEND_CHANGE,
    GET_FORM_GROUP,
    ATTRIBUTE_FORM_ATTR_CHANGE,
    ATTRIBUTE_GROUP_FORM_ATTR_CHANGE,
    GET_FORM_GROUP_DETAIL,
    CREATE_FORM_GROUP_DETAIL,
    EDIT_FORM_GROUP_DETAIL,
    FORM_GROUP_STATUS,
    GET_ID_POOL_CONSUMER_ATTRIBUTE_AND_GROUPS,
  },
};
