import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { Colors, ColorSet } from 'admin/define/colors';
import { RuleType } from 'admin/types/userPool/rule';
import { AgreeFormType } from 'admin/types/userPool/rule/form';
import { FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { IoIosAlert } from 'react-icons/io';

type Props = {
  onClose: () => void;
  onSubmit: (value: AgreeFormType) => void;
  rule: RuleType | null;
  isLoading: boolean;
};

export const AgreeModalComponent: FC<Props> = ({
  onClose,
  onSubmit,
  rule,
  isLoading,
}) => {
  const { handleSubmit } = useFormContext<AgreeFormType>();
  const { errors, isValid } = useFormState<AgreeFormType>();
  const formId = 'agree';

  return (
    <>
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100}>
        <ModalHeader fontSize="lg" fontWeight="bold">
          現バージョンへの同意を必須にする
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <ModalBody>
            <Text fontSize="sm" fontWeight="bold">
              規約名
            </Text>
            <Text py="2" px="4">
              {rule?.displayName}
            </Text>
            <Divider />
            <Text fontWeight="bold" mb="1" mt="4">
              現在のバージョンの規約への同意を必須とする機能です。
            </Text>
            <Text>
              これ以前のバージョンに同意しているユーザーに対しても、最新のバージョンへの同意を強制的に取得します。
            </Text>
            <Box
              p="4"
              mt="4"
              mb="4"
              bgColor="#FDEDF0"
              borderRadius={8}
              color={Colors.RED_20}
            >
              <Text
                fontWeight="bold"
                fontSize="sm"
                display="flex"
                alignItems="center"
              >
                <Icon as={IoIosAlert} w="24px" h="24px" mr="4" />
                ・一度実行すると元の状態に戻せないため注意してください
              </Text>
            </Box>
            <Text mb={4} fontSize="sm" fontWeight="bold">
              実行する場合は「agree」と入力後に「実行ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.message?.message)}>
              <RhfInput<AgreeFormType> name="message" type="text" />
              <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
        </form>
        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="solid" {...ColorSet.Default} onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="submit"
              form={formId}
              colorScheme="primary"
              mr={3}
              disabled={!isValid || isLoading}
            >
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </>
  );
};
