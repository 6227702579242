import { ChangeEvent, VFC } from 'react';
import {
  Table,
  Tr,
  Tbody,
  Td,
  Box,
  Text,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

type Props = {
  isDeleteInsert: boolean;
  isAutoImport: boolean;
  onChangeIsDeleteInsert: (event: ChangeEvent<HTMLInputElement>) => void;
};

const isDeleteInsertToolTipMsg =
  'オンにすると、全件削除してからファイルデータを反映します。オフの場合には、追加・更新のみの処理となり、削除はされません。';

export const PublishDeleteInsert: VFC<Props> = ({
  isDeleteInsert,
  isAutoImport,
  onChangeIsDeleteInsert,
}) => (
  <Table size="sm">
    <Tbody>
      <Tr>
        <Td position="relative">
          <Checkbox
            isChecked={isDeleteInsert}
            onChange={onChangeIsDeleteInsert}
            isDisabled={!isAutoImport}
          >
            <Text fontSize="sm">洗い替え取り込み</Text>
          </Checkbox>
          <Box
            position="absolute"
            top="50%"
            left="160px"
            transform="translateY(-50%)"
          >
            <Tooltip label={isDeleteInsertToolTipMsg}>
              <Box flex="1" textAlign="left">
                <BsFillQuestionCircleFill color="gray" />
              </Box>
            </Tooltip>
          </Box>
        </Td>
      </Tr>
    </Tbody>
  </Table>
);
