import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  ServiceRuleFormType,
  ServiceRuleResponseType,
} from 'admin/types/service/rule/index';
import { z } from 'zod';
import { isPositiveInteger } from '../util/index';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const serviceRuleSchema = z.object<toZod<ServiceRuleResponseType>>({
  id: z.string(),
  name: z.string(),
  displayName: z.string(),
  displayEnName: z.string(),
  fieldName: z.string(),
  version: z.number(),
  url: z.string(),
  agreeRequiredVesion: z.optional(z.number().nullable()),
  status: z.boolean(),
  sortNumber: z.number().min(1, '並び順は必須です'),
});

export const serviceRulesSchema = z.array(serviceRuleSchema);

export const serviceRuleFormSchema = z.object<toZod<ServiceRuleFormType>>({
  id: z.string(),
  sortNumber: z
    .string()
    .min(1, '並び順は必須です')
    .refine((val) => isPositiveInteger(val), {
      message: '正数値のみが許可されます。小数点以下は含められません。',
    }),
  status: z.boolean(),
});
