import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useLogs } from 'admin/hooks/userPool/rule/useLogs';
import { ErrorContents } from 'components/common/atoms';
import { FC } from 'react';
import { LogTableComponent } from './LogTableComponent';

export const LogTable: FC = () => {
  const idPoolConsumerId = useIdPoolConsumerId();

  const { data: logs } = useLogs(idPoolConsumerId);
  if (!logs) {
    return null;
  }

  return <LogTableComponent logs={logs} />;
};

export const Log = withSuspenseAndErrorBoundary(LogTable, {
  ErrorComponent: <ErrorContents name="プライバシーポリシー更新履歴" />,
});
