import { Modal, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { StatusChangeModal } from 'admin/components/idPoolConsumer/rule/statusChangeModal';
import { useEditRule, useRule } from 'admin/hooks/userPool/rule/';
import { editSubmitRuleSchema } from 'admin/schema/idPoolConsumer/rule';
import {
    EditRuleFormInputType,
    EditRuleFormOutputType
} from 'admin/types/userPool/rule/form';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { RuleEditFormComponent } from './RuleEditFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
  ruleId: string;
};

const FetchRuleEditForm: FC<Props> = memo(
  ({ onClose, formId, ruleId }: Props) => {
    const { data: ruleValue } = useRule(ruleId);
    const [modalPromise, setModalPromise] = useState<() => void>(() => null);
    const { mutate, isLoading } = useEditRule();
    const {
      isOpen: isModalOpen,
      onClose: onModalClose,
      onOpen: onModalOpen,
    } = useDisclosure();
    const methods = useForm<EditRuleFormInputType>({
      defaultValues: {
        fieldName: ruleValue?.fieldName,
        displayName: ruleValue?.displayName,
        displayEnName: ruleValue?.displayEnName,
        url: ruleValue?.url,
        status: ruleValue?.status,
        canVersionUp: 'false',
      },
      resolver: zodResolver(editSubmitRuleSchema),
    });

    const statusValue = methods.watch('status');

    const onSubmit: SubmitHandler<EditRuleFormOutputType> = async (
      data: EditRuleFormOutputType,
    ) => {
      const submitData = {
        ...data,
        id: ruleId,
      };
      const status = methods.getValues('status');
      if (status !== ruleValue?.status) {
        onModalOpen();
        const promise = new Promise<void>((resolve) => {
          setModalPromise(() => resolve);
        });
        await promise;
        onModalClose();
      }

      await mutate(submitData);
      onClose();
    };

    return (
      <>
        <FormProvider {...methods}>
          <RuleEditFormComponent
            onSubmit={onSubmit}
            formId={formId}
            isLoading={isLoading}
          />
        </FormProvider>
        <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered size="xl">
          <StatusChangeModal
            onClose={onClose}
            onSubmit={modalPromise}
            status={statusValue}
          />
        </Modal>
      </>
    );
  },
);

export const RuleEditForm = withSuspenseAndErrorBoundary(FetchRuleEditForm);
