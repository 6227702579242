import { AdminAuth, AuthConfigType } from 'admin/config/adminAuth';
import { useMenuPermission } from 'admin/hooks/useMenuPermission';
import { useUserInfo } from 'hooks/useUserInfo';

export const useAdminAuth = (): {
  isAllowedPermission: (key: string) => boolean;
  isAllowedMenuPermission: (menuName: string) => boolean;
} => {
  const { userTenant, getPermissionKey } = useUserInfo();

  const {
    hasAllowedIdPoolConsumer,
    hasAllowedService,
    hasAllowedSaml,
    hasAllowedMember,
  } = useMenuPermission();

  const hasAllowed = (key: string, permissionKey: keyof AuthConfigType) =>
    AdminAuth[key][permissionKey];

  // ログイン中のユーザー権限のアクセスが許可されているかどうか判定
  const isAllowedPermission = (key: string): boolean => {
    const permissionKey = getPermissionKey(
      userTenant?.permission as number,
    ) as keyof AuthConfigType;

    return hasAllowed(key, permissionKey);
  };

  const isAllowedMenuPermission = (menuName: string): boolean => {
    switch (menuName) {
      case 'idPoolConsumer':
        return hasAllowedIdPoolConsumer;
      case 'service':
        return hasAllowedService;
      case 'saml':
        return hasAllowedSaml;
      case 'member':
        return hasAllowedMember;
      default:
        return true;
    }
  };

  return {
    isAllowedPermission,
    isAllowedMenuPermission,
  };
};
