import { AttributeGroupTableInnerComponent } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupTableInner/AttributeGroupTableInnerComponent';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  attributeGroup: AttributeGroupType;
  onOpen: (id: AttributeGroupType['id']) => void;
  setTargetAttributeGroup: Dispatch<
    SetStateAction<AttributeGroupType | undefined>
  >;
  onOpenStatusDialog: () => void;
  onOpenAutoAppendDialog: () => void;
  onOpenFormAttrDialog: () => void;
};

export const AttributeGroupTableInner: FC<Props> = memo((props) => (
  <AttributeGroupTableInnerComponent {...props} />
));
