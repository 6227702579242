import { Box } from '@chakra-ui/react';
import { CircleIcon } from 'admin/components/ui/icon/circleIcon';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  status: boolean;
  onLabel?: string;
  offLabel?: string;
};

export const Status: FC<Props> = memo(({ status, onLabel, offLabel }) => {
  if (status) {
    return (
      <Box
        width="60px"
        height="24px"
        borderRadius={4}
        color={Colors.BLUE_00}
        bg={Colors.BLUE_30}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircleIcon boxSize={3} color={Colors.BLUE_00} mr={1} />
        {onLabel || '有効'}
      </Box>
    );
  }

  return (
    <Box
      width="60px"
      height="24px"
      borderRadius={4}
      color="black"
      bg={Colors.GRAY_40}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircleIcon boxSize={3} solid color="black" mr={1} />
      {offLabel || '無効'}
    </Box>
  );
});
