import { AttributeTableBodyComponent } from 'admin/components/service/attribute/attributeTableBody/AttributeTableBodyComponent';
import { useGetCodeGroupName } from 'admin/hooks/userPool/master/useGetCodeGroupName';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attribute: ServiceAttributeType;
  onOpen: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenFormAttrDialog: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenChildAttrDialog: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
};

export const AttributeTableBody: FC<Props> = memo(
  ({
    attribute,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
  }: Props) => {
    const codeGroupName = useGetCodeGroupName(attribute.userPool);

    return (
      <AttributeTableBodyComponent
        attribute={attribute}
        onOpen={onOpen}
        onOpenFormAttrDialog={onOpenFormAttrDialog}
        onOpenChildAttrDialog={onOpenChildAttrDialog}
        codeGroupName={codeGroupName}
      />
    );
  },
);
