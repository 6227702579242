import { changeRuleStatus } from 'admin/api/userPool/rule/changeRuleStatus';
import { CustomError } from 'admin/error/CustomError';
import { RulesType } from 'admin/types/userPool/rule/index';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useChangeRuleStatus = (
  id: string,
  currentPage: Page['currentPage'],
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (value: { id: string; status: boolean }) =>
      changeRuleStatus(value.id, value.status),
    onMutate: async (newData) => {
      await queryClient.cancelQueries(queryKeys.getRules(id, currentPage));
      const previousUserData = queryClient.getQueryData<
        DataWithPage<RulesType>
      >(queryKeys.getRules(id, currentPage));
      if (previousUserData) {
        const setData = previousUserData.results.map((rule) => {
          if (rule.id === newData.id) {
            return {
              ...rule,
              status: newData.status,
            };
          }

          return rule;
        });
        const response = {
          ...previousUserData,
          results: setData,
        };

        queryClient.setQueriesData(
          queryKeys.getRules(id, currentPage),
          () => response,
        );
      }
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ステータスの更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
      await queryClient.invalidateQueries(queryKeys.getRules(id, currentPage));
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'ステータスを更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
