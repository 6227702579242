import { Flex, HStack, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

const BreadcrumbDivider: FC = () => (
  <Text as="span" color={Colors.BLACK} fontWeight="400">
    &nbsp;／&nbsp;
  </Text>
);

type Props = {
  breadcrumbItems: BreadcrumbType[];
};

export const Breadcrumb: FC<Props> = memo(({ breadcrumbItems }: Props) => {
  const isLastChild = (idx: number) => breadcrumbItems.length - 1 === idx;

  return (
    <HStack divider={<BreadcrumbDivider />}>
      {breadcrumbItems.map((item, idx) => (
        <NavLink to={item.url} key={String(idx)}>
          <Flex>
            <Text
              fontSize="14px"
              color={isLastChild(idx) ? 'gray.800' : '#7E8C9A'}
              fontWeight={isLastChild(idx) ? 700 : 400}
              cursor={isLastChild(idx) || !item.url ? 'default' : 'pointer'}
              textDecoration={isLastChild(idx) || !item.url ? 'none' : 'underline'}
            >
              {item.title}
            </Text>
          </Flex>
        </NavLink>
      ))}
    </HStack>
  );
});
