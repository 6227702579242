import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { RuleTable } from 'admin/components/service/rule/ruleTable';
import { RuleTitle } from 'admin/components/service/rule/ruleTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { useService } from 'admin/hooks/service/useService';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const FetchServiseRule: FC = memo(() => {
  useSetPageTitle('規約設定');
  const params = useParams();

  const serviceId = useMemo(() => params.serviceId ?? '', [params]);
  const { data: service } = useService(serviceId);

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/service', title: 'テナント設定管理' },
    { url: '', title: '規約設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <RuleTitle serviceName={service?.name ?? ''} />
      <RuleTable />
    </>
  );
});

export const ServiseRule = withSuspenseAndErrorBoundary(FetchServiseRule, {
  ErrorComponent: <ErrorContents name="規約設定" />,
  LoadingComponent: <LoadingSpinner />,
});
