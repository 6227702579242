import { Box, BoxProps } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

export const RequiredIcon: FC<BoxProps> = memo(
  ({ children: _children, ...props }) => (
    <Box {...props}>
      <Box
        width="60px"
        height="20px"
        borderRadius={4}
        color={Colors.RED_20}
        border={`solid 1px ${Colors.RED_20}`}
        display="flex"
        fontSize="12px"
        alignItems="center"
        justifyContent="center"
      >
        必須
      </Box>
    </Box>
  ),
);
