import { Box, Flex, Text, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { LogsType } from 'admin/types/userPool/rule';
import { FC, memo } from 'react';

type LogTableComponentProps = {
  logs: LogsType;
};

export const LogTableComponent: FC<LogTableComponentProps> = memo(
  ({ logs }: LogTableComponentProps) => (
    <Box>
      <Box mt="40px" mb="16px">
        <Flex justifyContent="flex-end">
          <Box mr="auto">
            <Text
              fontFamily="Hiragino Sans"
              fontSize="18px"
              fontWeight="700"
              lineHeight="170%"
            >
              プライバシーポリシー更新履歴
            </Text>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
              color={Colors.GRAY_00}
            >
              プライバシーポリシー更新履歴についての説明
            </Text>
          </Box>
        </Flex>
      </Box>
      <Table maxHeight="calc(100vh - 250px)">
        <Thead>
          <ChakraTr>
            <Th maxWidth="80px" minWidth="80px">
              バージョン
            </Th>
            <Th minWidth="150px">更新内容</Th>
            <Th minWidth="150px">メモ</Th>
            <Th maxWidth="120px" minWidth="120px">
              更新日時
            </Th>
            <Th maxWidth="150px" minWidth="100px">
              更新者名
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {logs.map((log) => (
            <Tr key={`rule${log.id}`}>
              <Td maxWidth="80px" minWidth="80px">
                {log.version}
              </Td>
              <Td minWidth="150px">{log.updateContent}</Td>
              <Td minWidth="150px">{log.memo}</Td>
              <Td maxWidth="120px" minWidth="120px">
                {log.updatedTiming}
              </Td>
              <Td maxWidth="150px" minWidth="100px">
                {log.updaterName}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
