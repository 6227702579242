import { mailInsertWordsPattern, mailInsertWordTitleObject, statusTitleObject, statusTitleObjectMessage } from 'components/paywall/pages/MailSetting/constants';
import {
  MailInsertWordKeyPattern, MailSettingStatuses
} from 'components/paywall/pages/MailSetting/typed';
import { camelCase } from 'utils/str';

/**
 * 個別情報のステータスのキーを変換するための関数
 * @param {PersonalStatusCamelKeysModel} type
 * @returns
 */
export const convertStatusTitle = (
  type: keyof MailSettingStatuses,
): string => {
  const convertStr = camelCase(type) as keyof MailSettingStatuses;

  return statusTitleObject[convertStr];
};

export const convertStatusTitleMessage = (
  type: keyof MailSettingStatuses,
): string => {
  const convertStr = camelCase(type) as keyof MailSettingStatuses;

  return statusTitleObjectMessage[convertStr];
};

/**
 * 差し込み文言タイトル生成
 * @param {MailInsertWordKeyPattern} type 差し込み文言のタイトルのキーとなるものを入力
 * @returns
 */
export const convertInsertWordTitle = (type: MailInsertWordKeyPattern): string =>
  mailInsertWordTitleObject[type];

export const convertInsertWordArray = (type: keyof MailSettingStatuses) => {
  const filterArr = Object.entries(mailInsertWordsPattern[type])
    .map(([key, value]) => ({
      key: key as MailInsertWordKeyPattern,
      enabled: value,
    }))
    .filter((first) => first.enabled === true);

  if (filterArr.length !== 0) {
    return filterArr.map((second) => ({
      tag: second.key,
      caption: convertInsertWordTitle(second.key),
    }));
  }

  return [];
};
