import { changeCommonUse } from 'admin/api/userPool/rule/changeCommonUse';
import { CustomError } from 'admin/error/CustomError';
import { UserPool } from 'admin/types/userPool';
import { RulesType, RuleType } from 'admin/types/userPool/rule';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useChangeCommonUse = (
  id: UserPool['id'],
  currentPage: Page['currentPage'],
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (value: RuleType['id']) => changeCommonUse(value),
    onMutate: async (newData) => {
      await queryClient.cancelQueries(queryKeys.getRules(id, currentPage));
      const previousUserData = queryClient.getQueryData<
        DataWithPage<RulesType>
      >(queryKeys.getRules(id, currentPage));
      if (previousUserData) {
        const setData = previousUserData.results.map((rule) => {
          if (rule.id === newData) {
            return {
              ...rule,
              commonUse: true,
            };
          }

          return rule;
        });

        const response = {
          ...previousUserData,
          results: setData,
        };

        queryClient.setQueriesData(
          queryKeys.getRules(id, currentPage),
          () => response,
        );
      }
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'すべてのサービス共通で利用する設定の変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'すべてのサービス共通で利用する設定に変更しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
