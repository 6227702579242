import {
  Box,
  DrawerBody,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react';
import { RhfCheckbox } from 'admin/components/form/RhfCheckbox';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors } from 'admin/define/colors';
import { CreateRuleFormType } from 'admin/types/userPool/rule/form';
import { ChangeEvent, FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type RuleCreateFormComponentProps = {
  formId: string;
  onSubmit: (value: CreateRuleFormType) => void;
  isLoading: boolean;
};
export const RuleCreateFormComponent: FC<RuleCreateFormComponentProps> = memo(
  ({ onSubmit, formId, isLoading }) => {
    const { handleSubmit, clearErrors } = useFormContext<CreateRuleFormType>();
    const { errors } = useFormState<CreateRuleFormType>();

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayName', 'displayEnName']);
      }
    };

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody py="24px" px="0" borderTopWidth="1px" mx="24px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  <Box display="flex" alignItems="bottom">
                    表示名 <RequiredIcon ml="8px" />
                  </Box>
                  <Text fontSize="12px" fontWeight="normal" mt="8px" mb="16px">
                    日本語・外国語、
                    <Box as="span" color={Colors.RED_20} fontWeight="bold">
                      いずれか1つ
                    </Box>
                    は必須です。
                  </Text>
                </Heading>
                <Box bg="#F7FAFC" p="16px" borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" size="sm" mb={2}>
                      表示名（日本語）
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={Boolean(errors.displayName?.message)}
                      >
                        <Box>
                          <RhfInput<CreateRuleFormType>
                            name="displayName"
                            bgColor={Colors.WHITE}
                            placeholder="例: プライバシーポリシー"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.displayName?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" size="sm" mb={2}>
                      表示名（外国語）
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={Boolean(errors.displayEnName?.message)}
                      >
                        <Box>
                          <RhfInput<CreateRuleFormType>
                            name="displayEnName"
                            bgColor={Colors.WHITE}
                            placeholder="例: privacy policy"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.displayEnName?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.fieldName?.message)}>
                  <Heading as="h3" size="sm" mb={1}>
                    フィールド名
                  </Heading>
                  <Box>
                    <Text mb={2} fontSize="12px">
                      半角英数字で入力してください
                    </Text>
                    <RhfInput<CreateRuleFormType>
                      name="fieldName"
                      bgColor={Colors.WHITE}
                      placeholder="例: privacy_policy"
                    />
                    <FormErrorMessage>
                      {errors.fieldName?.message}
                    </FormErrorMessage>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.url?.message)}>
                  <Heading as="h3" size="sm" mb={2}>
                    規約ページのURL
                  </Heading>
                  <Box>
                    <RhfInput<CreateRuleFormType>
                      name="url"
                      bgColor={Colors.WHITE}
                    />
                    <FormErrorMessage>{errors.url?.message}</FormErrorMessage>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  ステータス
                </Heading>
                <Box>
                  <RhfCheckbox<CreateRuleFormType> name="status">
                    有効にする
                  </RhfCheckbox>
                </Box>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
      </>
    );
  },
);
