import { CardItems } from 'components/paywall/features/Card';
import { CourseCardItem } from 'components/paywall/pages/CourseList/CourseCardItem';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { FC, memo } from 'react';

type Props = {
  course: Course;
}

export const CourseCardItems: FC<Props> = memo(({course}: Props) => (
  <CardItems>
    <CourseCardItem course={course} />
  </CardItems>
))
