import { UserPool } from 'admin/types/userPool';
import { RuleType } from 'admin/types/userPool/rule';
import { Page } from 'api/common/types';

export const queryKeys = {
  getRules: (
    id: UserPool['id'],
    currentPage: Page['currentPage'],
  ) => ['getRules', id, currentPage],
  getRule: (id: RuleType['id']) => ['getRule', id],
  getLogs: (id: RuleType['id']) => ['getLogs', id],
  changeAgreeRule: (id: RuleType['id']) => ['changeAgreeRule', id],
} as const;
