import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeAgreeRule } from 'admin/hooks/userPool/rule';
import { agreeFormSchema } from 'admin/schema/idPoolConsumer/rule';
import { RuleType } from 'admin/types/userPool/rule';
import { AgreeFormType } from 'admin/types/userPool/rule/form';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AgreeModalComponent } from './AgreeModalComponent';

type FetchAgreeModalProps = {
  onClose: () => void;
  rule: RuleType | null;
};

const FetchAgreeModal: FC<FetchAgreeModalProps> = memo(
  ({ onClose, rule }: FetchAgreeModalProps) => {
    const { mutate, isLoading } = useChangeAgreeRule();
    const methods = useForm<AgreeFormType>({
      defaultValues: {
        message: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(agreeFormSchema),
    });

    const onSubmit: SubmitHandler<AgreeFormType> = async () => {
      await mutate(rule?.id ?? '');
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <AgreeModalComponent
          onClose={onClose}
          onSubmit={onSubmit}
          rule={rule}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);

export const AgreeModal = withSuspenseAndErrorBoundary(FetchAgreeModal);
