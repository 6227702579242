import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { rulesSchema } from 'admin/schema/idPoolConsumer/rule';
import { RulesType, RuleType } from 'admin/types/userPool/rule';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const changeAgreeRule = async (
  id: RuleType['id'],
): Promise<RulesType> => {
  console.log(id);
  // const path = userId ? `user/${userId}/` : 'user/';

  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'post',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  // ダミーデータ
  const dummyData = [
    {
      fieldName: 'dummyFieldName1',
      displayName: 'dummyDisplayName1',
      displayEnName: 'dummyDisplayName1',
      id: '001',
      version: 1,
      url: 'https://dummy.com',
      agreeRequiredVesion: 1,
      status: false,
      commonUse: false,
    },
    {
      fieldName: 'dummyFieldName2',
      displayName: 'dummyDisplayName2',
      displayEnName: 'dummyDisplayName1',
      id: '002',
      version: 1,
      url: 'https://dummy.com',
      agreeRequiredVesion: null,
      status: true,
      commonUse: false,
    },
    {
      fieldName: 'dummyFieldName3',
      displayName: 'dummyDisplayName3',
      displayEnName: 'dummyDisplayName1',
      id: '003',
      version: 2,
      url: 'https://dummy.com',
      agreeRequiredVesion: 1,
      status: true,
      commonUse: false,
    },
  ];
  const response = await getDummySuccessResponse<RulesType>(dummyData);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CHANGE_AGREE_RULE.FAILED,
        {
          path: 'dummy',
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CHANGE_AGREE_RULE.ERROR,
        {
          path: 'dummy',
          status: response.status,
        },
      );
    }
  }
  try {
    rulesSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
