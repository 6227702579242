import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceRuleSchema } from 'admin/schema/service/rule';
import {
  ServiceRuleFormType,
  ServiceRuleType,
} from 'admin/types/service/rule/index';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const editRule = async (
  data: ServiceRuleFormType,
): Promise<ServiceRuleType> => {
  console.log(data);
  // const path = userId ? `user/${userId}/` : 'user/';

  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'post',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  const response = await getDummySuccessResponse<ServiceRuleType>({
    name: 'dummyDataName1',
    fieldName: 'dummyFieldName1',
    displayEnName: 'dummyDisplayEnName1',
    displayName: 'dummyDisplayName1',
    id: '001',
    version: 1,
    url: 'https://dummy.com',
    agreeRequiredVesion: 1,
    status: false,
    sortNumber: 1,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_RULE.FAILED, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_RULE.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    serviceRuleSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
