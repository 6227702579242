/* eslint-disable react/require-default-props */
import { HStack, ListItem, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  label?: string;
  text?: string | JSX.Element;
  labelMinW?: string;
  isSpace?: boolean;
  children?: React.ReactNode | null;
}

export const CardItemListItem: FC<Props> = memo(({label = '', text = '', labelMinW = '140px', isSpace = false, children = null}: Props) => (
  <ListItem mt={isSpace ? "8px" : ""}>
    <HStack align="flex-start" spacing="12px" fontFamily="Hiragino sans-serif">
      {children === null && (
        <>
          <Text width={labelMinW} minW={labelMinW} color={Colors.GRAY_10} >{label}</Text>
          <Text fontSize="14px" fontWeight="400" maxW={`calc(100% - (${labelMinW} + 12px))`} color={Colors.GRAY_03} >{text}</Text>
        </>
      )}
      {children !== null && (
        <>
          {children}
        </>
      )}
    </HStack>
  </ListItem>
))
