import { patchPromotion } from 'api/paywall/patchPromotion';
import { ResponseError } from 'api/types';
import { useSearch } from 'components/opt/hooks/useSearch';
import { PromotionFormDefaultValues } from 'components/paywall/pages/PromotionList/initial';
import {
  isPromotion, Promotion, PromotionFormType, PromotionSubmitFormType
} from 'components/paywall/pages/PromotionList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * プロモーション修正 hooks
 * @param {UseFormSetError<PromotionFormType>} setError
 * @param {Function} close
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditPromotion = ({
  setError,
  close,
  reset,
}: {
  setError?: UseFormSetError<PromotionFormType>;
  close?: () => void;
  reset?: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchPromotion>>,
    unknown,
    PromotionSubmitFormType,
    unknown
  >;
  isLoading: boolean;
} => {
  const toast = useCustomToast();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const {
    paywallCourseListRefetchQueries,
    paywallPromotionListRefetchQueries,
  } = usePaywallUtil();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, courseId, promotionForm }: PromotionSubmitFormType) =>
      patchPromotion({ tenantId, courseId, promotionForm }),
    {
      onSuccess: (result: Promotion | boolean | ResponseError) => {
        paywallCourseListRefetchQueries();
        paywallPromotionListRefetchQueries(param);

        if (isPromotion(result)) {
          close?.();
          reset?.();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'プロモーションを修正しました',
          });
        }

        if (
          isGetValidationError<Promotion>(
            result,
            Object.keys(PromotionFormDefaultValues),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof PromotionFormType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError?.(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
