import { BoxProps, Th as ChakraTh } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & BoxProps;

export const Th: FC<Props> = memo(({ children, ...rest }: Props) => (
  <ChakraTh py="10px" px="16px" {...rest}>
    {children}
  </ChakraTh>
));
