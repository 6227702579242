import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useCreateRule } from 'admin/hooks/userPool/rule/useCreateRule';
import { createRuleSchema } from 'admin/schema/idPoolConsumer/rule';
import { CreateRuleFormType } from 'admin/types/userPool/rule/form';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { RuleCreateFormComponent } from './RuleCreateFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
};

const FetchRuleCreateForm: FC<Props> = memo(({ onClose, formId }: Props) => {
  const { mutate, isLoading } = useCreateRule();
  const methods = useForm<CreateRuleFormType>({
    defaultValues: {
      fieldName: '',
      displayName: '',
      displayEnName: '',
      url: '',
      status: false,
    },
    resolver: zodResolver(createRuleSchema),
  });

  const onSubmit: SubmitHandler<CreateRuleFormType> = async (
    data: CreateRuleFormType,
  ) => {
    await mutate(data);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <RuleCreateFormComponent
        onSubmit={onSubmit}
        formId={formId}
        isLoading={isLoading}
      />
    </FormProvider>
  );
});

export const RuleCreateForm = withSuspenseAndErrorBoundary(FetchRuleCreateForm);
