import { dummyData } from 'admin/api/userPool/rule/getRules';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { rulesSchema } from 'admin/schema/idPoolConsumer/rule';
import { RulesType, RuleType } from 'admin/types/userPool/rule';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const changeRuleStatus = async (
  id: RuleType['id'],
  status: RuleType['status'],
): Promise<RulesType> => {
  console.log(id, status);
  // const path = userId ? `user/${userId}/` : 'user/';

  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'post',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  const setData = dummyData.map((item) => {
    if (id === item.id) {
      return {
        ...item,
        status,
      };
    }

    return item;
  });

  const response = await getDummySuccessResponse<RulesType>(setData);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CHANGE_RULE_STATUS.FAILED,
        {
          path: 'dummy',
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CHANGE_RULE_STATUS.ERROR,
        {
          path: 'dummy',
          status: response.status,
        },
      );
    }
  }
  try {
    rulesSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
