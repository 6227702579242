// ----- fetch関連 -----
const GET_SERVICES = {
  ERROR:
    '申し訳ございません。テナント一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'テナント一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_SERVICE = {
  ERROR:
    '申し訳ございません。テナント情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'テナントが存在しません。既に削除された可能性があります。',
} as const;
const GET_SETTING = {
  ERROR:
    '申し訳ございません。その他の設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'その他の設定情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_SETTIN_DETAIL = {
  ERROR:
    '申し訳ございません。その他の設定の詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'その他の設定の詳細情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_RULE = {
  ERROR:
    '申し訳ございません。規約設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '規約設定が存在しません。既に削除された可能性があります。',
} as const;
const GET_RULES = {
  ERROR:
    '申し訳ございません。規約設定一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '規約設定一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_SEVICE_ATTRIBUTE = {
  ERROR: "申し訳ございません。属性一覧情報の取得ができません。時間を置いて再表示をお願いします。",
  NOT_FOUND: "属性一覧情報が存在しません。既に削除された可能性があります。",
} as const;
const GET_SEVICE_ATTRIBUTE_DETAIL = {
  ERROR: "申し訳ございません。属性利用設定情報の取得ができません。時間を置いて再表示をお願いします。",
  NOT_FOUND: "属性利用設定情報が存在しません。既に削除された可能性があります。",
} as const;
const GET_SEVICE_ATTRIBUTE_GROUPS = {
  ERROR: "申し訳ございません。属性グループ一覧情報の取得ができません。時間を置いて再表示をお願いします。",
  NOT_FOUND: "属性グループ一覧情報が存在しません。既に削除された可能性があります。",
} as const;
const GET_SEVICE_ATTRIBUTE_GROUP_DETAIL = {
  ERROR: "申し訳ございません。属性グループ利用設定情報の取得ができません。時間を置いて再表示をお願いします。",
  NOT_FOUND: "属性グループ利用設定情報が存在しません。既に削除された可能性があります。",
} as const;

// ----- Mutate関連 -----
const UPDATE_SERVICE = {
  ERROR: '申し訳ございません。ユーザープールの割り当てに失敗しました。',
  FAILED:
    'ユーザープールの割り当てに失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_SETTING_WHITELIST = {
  ERROR: '申し訳ございません。ホワイトリストの保存に失敗しました。',
  FAILED:
    'ホワイトリストの保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_SEVICE_ATTRIBUTE = {
  ERROR: "申し訳ございません。属性利用設定の保存に失敗しました。",
  FAILED: "属性利用設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。",
} as const;
const UPDATE_SEVICE_ATTRIBUTE_GROUP = {
  ERROR: "申し訳ございません。属性グループ利用設定の保存に失敗しました。",
  FAILED: "属性グループ利用設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。",
} as const;
const UPDATE_RULE = {
  ERROR: '申し訳ございません。規約設定の保存に失敗しました。',
  FAILED:
    '規約設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_ATTRIBUTE_AUTO_APPEND = {
  ERROR: '申し訳ございません。「全テナント割当」の変更に失敗しました。',
  FAILED:
    '「全テナント割当」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_ATTRIBUTE_FORM_ATTR_CHANGE = {
  ERROR: '申し訳ございません。「全フォーム利用」の変更に失敗しました。',
  FAILED:
    '「全フォーム利用」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const SERVICE = {
  SERVICE: {
    GET_SERVICES,
    GET_SERVICE,
    GET_SETTING,
    GET_SETTIN_DETAIL,
    GET_SEVICE_ATTRIBUTE,
    GET_SEVICE_ATTRIBUTE_DETAIL,
    GET_SEVICE_ATTRIBUTE_GROUPS,
    GET_SEVICE_ATTRIBUTE_GROUP_DETAIL,
    GET_RULE,
    GET_RULES,
    UPDATE_SERVICE,
    UPDATE_SETTING_WHITELIST,
    UPDATE_SEVICE_ATTRIBUTE,
    UPDATE_SEVICE_ATTRIBUTE_GROUP,
    UPDATE_RULE,
    CHANGE_SEVICE_ATTRIBUTE_AUTO_APPEND,
    CHANGE_SEVICE_ATTRIBUTE_FORM_ATTR_CHANGE,
  },
};
