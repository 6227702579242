import { Box, ListItem, Tr as ChakraTr, UnorderedList } from '@chakra-ui/react';
import { AttributeGroupTableBody } from 'admin/components/service/attribute/attributeGroupTableBody';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import {
  Table,
  Tbody,
  Th,
  Thead,
  ThWithBorder
} from 'admin/components/ui/table';
import {
  ServiceAttributeGroupsType,
  ServiceAttributeGroupType,
  UserPoolChildAttributeType
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attributeGroups: ServiceAttributeGroupsType;
  onOpen: (
    id: ServiceAttributeGroupType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenFormAttrDialog: (
    id: ServiceAttributeGroupType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenChildAttrDialog: (
    id: ServiceAttributeGroupType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
};

export const AttributeGroupTableComponent: FC<Props> = memo(
  ({
    attributeGroups,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
  }: Props) => (
    <Box>
      <Table w="100%" maxHeight="calc(100vh - 420px)">
        <Thead>
          <ChakraTr>
            <Th
              maxWidth="130px"
              minWidth="130px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              属性グループID
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              表示名（日本語）
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              表示名（外国語）
            </Th>
            <Th
              maxWidth="161px"
              minWidth="161px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              画面上の配置
            </Th>
            <ThWithBorder
              maxWidth="230px"
              minWidth="230px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              使用する属性
            </ThWithBorder>
            <Th
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              テナント割当
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性グループが操作中のテナントで利用可能になります。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            <ThWithBorder
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              フォームで利用
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性グループが操作中のテナントのフォームで表示されます。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </ThWithBorder>
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributeGroups.map((attributeGroup, idx) => (
            <AttributeGroupTableBody
              attributeGroup={attributeGroup}
              onOpen={onOpen}
              onOpenFormAttrDialog={onOpenFormAttrDialog}
              onOpenChildAttrDialog={onOpenChildAttrDialog}
              key={`attribute-group-table-body_${String(idx)}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
