import { Box, VStack } from '@chakra-ui/react';
import { CourseCard } from 'components/paywall/pages/CourseList/CourseCard';
import { useCourseList } from 'hooks/paywall/useCourseList';
import { FC, Fragment, memo } from 'react';
import { useParams } from 'react-router-dom';

export const CourseCardList: FC = memo(() => {
  const params = useParams();
  const { courseList } = useCourseList({
    wallId: params.wallId || '',
  });

  return (
    <Box minW="855px" className="CourseCardList">
      <VStack spacing='40px' alignItems="flex-end">
        {courseList.map((item) => (
          <Fragment key={item.id}>
            <CourseCard course={item} />
          </Fragment>
        ))}
      </VStack>
    </Box>
  )
})
