import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LogTable } from 'admin/components/idPoolConsumer/rule/logTable';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

const PlainLog: FC = memo(() => {
  const idPoolConsumerId = useIdPoolConsumerId();

  useSetPageTitle('プライバシーポリシー更新履歴');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    {
      url: `/admin/idPoolConsumer/${idPoolConsumerId}/rule`,
      title: '組織レベル規約設定',
    },
    {
      url: '',
      title: '組織レベル規約設定',
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <LogTable />
    </>
  );
});

export const Log = withSuspenseAndErrorBoundary(PlainLog, {
  ErrorComponent: <ErrorContents name="プライバシーポリシー更新履歴" />,
  LoadingComponent: <LoadingSpinner />,
});
