import { Box, VStack } from '@chakra-ui/react';
import { useTableNavigation } from 'components/paywall/features/Table/TableNavigation/hooks/useTableNavigation';
import { usePaywallCourse } from 'components/paywall/hooks/usePaywallCourse';
import { PromotionCard } from 'components/paywall/pages/PromotionList/PromotionCard';
import { usePromotionList } from 'hooks/paywall/usePromotionList';
import { FC, Fragment, memo, useEffect } from 'react';

export const PromotionCardList: FC = memo(() => {
  const { courseId } = usePaywallCourse({});
  const { promotionList, page } = usePromotionList({ courseId });
  const { setCount } = useTableNavigation();

  useEffect(() => {
    setCount(page.count);
  }, [setCount, page]);

  return (
    <Box minW="855px" className="CourseCardList">
      <VStack spacing='40px' alignItems="flex-end">
        {promotionList.map((item) => (
          <Fragment key={item.id}>
            <PromotionCard promotion={item} />
          </Fragment>
        ))}
      </VStack>
    </Box>
  )
})
