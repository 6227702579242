import { VFC, memo, useCallback, Dispatch, SetStateAction } from 'react';
import { Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { navigateState } from 'store/navigate';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { Dialog } from './Dialog';

type ChangeLocationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const ChangeLocationDialog: VFC<ChangeLocationDialogProps> = memo(
  ({ isOpen, onClose, setIsEdited }: ChangeLocationDialogProps) => {
    const navigatePath = useRecoilValue(navigateState);
    const { afterNavigate } = useCustomNavigate({});
    // OKの場合
    const submitBtnHandler = useCallback(() => {
      // react routerの制御時
      if (navigatePath !== '') {
        afterNavigate();

        // historyAPIの制御時
      } else {
        // historyAPIで戻るを実行します。
        window.history.back();
      }
      setIsEdited(false);
    }, [setIsEdited, afterNavigate, navigatePath]);

    // キャンセル
    const cancelBtnHandler = useCallback(() => {
      // キャンセルの場合、 ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
      window.history.pushState(null, '', null);
      onClose();
    }, [onClose]);

    return (
      <Dialog
        openBtnElemType="text"
        isOpen={isOpen}
        onOpen={() => null}
        openBtnChildNode={null}
        onClose={cancelBtnHandler}
        cancelBtnTitle="キャンセル"
        submitBtnTitle="OK"
        submitBtnHandelr={submitBtnHandler}
      >
        <Text as="p" mt={12} mb={2}>
          保存されていないデータは削除されますが、よろしいですか？
        </Text>
      </Dialog>
    );
  },
);
