import { CardItems } from 'components/paywall/features/Card';
import { PromotionCardItem } from 'components/paywall/pages/PromotionList/PromotionCardItem';
import { Promotion } from 'components/paywall/pages/PromotionList/typed';
import { FC, memo } from 'react';

type Props = {
  promotion: Promotion;
}

export const PromotionCardItems: FC<Props> = memo(({promotion}: Props) => (
  <CardItems>
    <PromotionCardItem promotion={promotion}/>
  </CardItems>
))
