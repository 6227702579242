import {
  Box,
  Button,
  Checkbox,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import {
  PERMISSION_FEATURES,
  PERMISSION_FEATURE_ADMIN_KEYS,
  PERMISSION_FEATURE_ADMIN_SAML,
  PERMISSION_FEATURE_ADMIN_SERVICE,
  PERMISSION_FEATURE_ADMIN_USER,
  PERMISSION_FEATURE_ADMIN_USER_POOL,
} from 'admin/define/permissions';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { PermissionFeatureKeyType } from 'admin/types/permission';
import { UserPoolType } from 'admin/types/user';
import { InviteCorporationSubmitForm } from 'admin/types/user/form';
import { CorporationUserPoolPermissionType } from 'api/user/types';
import {
  CORPORATION_USER_POOL_PERMISSION_TENANT,
  CORPORATION_USER_POOL_PERMISSION_USER_POOL,
} from 'define';
import { FC, memo, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { MdEmail } from 'react-icons/md';
import { toErrMsgList } from 'utils/form';

export type Props = {
  formId: string;
  userPermissionList: PermissionFeatureKeyType[];
  userPoolPermissionList: UserPoolType[];
  onChangePermissionCheck: (permission: PermissionFeatureKeyType) => void;
  onChangeUserPoolPermissionCheck: (
    index: number,
    userPoolKey: CorporationUserPoolPermissionType,
  ) => void;
  onClose: () => void;
  onSubmit: (data: InviteCorporationSubmitForm) => void;
};

export const UsersInviteFormComponent: FC<Props> = memo(
  ({
    formId,
    userPermissionList,
    userPoolPermissionList,
    onChangePermissionCheck,
    onChangeUserPoolPermissionCheck,
    onClose,
    onSubmit,
  }) => {
    const {
      handleSubmit,
      formState: { isSubmitting },
    } = useFormContext<InviteCorporationSubmitForm>();
    const { errors } = useFormState<InviteCorporationSubmitForm>();
    const { userPoolIdWithSaml } = useUserCorporationInfo();
    // 権限設定が可能なパーミッションリストの生成
    const permissionSettingList = useMemo(() => {
      const permissionList = [
        PERMISSION_FEATURE_ADMIN_USER,
        PERMISSION_FEATURE_ADMIN_SERVICE,
        PERMISSION_FEATURE_ADMIN_USER_POOL,
      ];

      // SAML設定権限が存在する場合のみ設定可能
      if (userPoolIdWithSaml !== '') {
        permissionList.push(PERMISSION_FEATURE_ADMIN_SAML);
      }

      return permissionList;
    }, [userPoolIdWithSaml]);

    return (
      <>
        {isSubmitting && <LoadingLayer />}
        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Stack spacing="24px" mt="24px">
              <Box w="100%">
                <Heading as="h3" display="flex" size="sm" mb={2}>
                  メールアドレス <RequiredIcon ml="8px" />
                </Heading>
                <FormControl
                  isInvalid={
                    Boolean(errors.emailTo?.message) ||
                    toErrMsgList(errors, 'emailTo').length > 0
                  }
                >
                  <Box>
                    <RhfInput<InviteCorporationSubmitForm>
                      name="emailTo"
                      bgColor={Colors.WHITE}
                    />
                  </Box>
                  {toErrMsgList(errors, 'emailTo').map((err) => (
                    <FormErrorMessage key={`emailTo${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
              <Box>
                <Text fontSize="18px" fontWeight="700">
                  権限
                </Text>
                <VStack spacing={2} mt="16px" alignItems="flex-start">
                  <FormControl
                    isInvalid={Boolean(errors.permission)}
                    display="grid"
                    gridGap="8px"
                  >
                    <Text fontWeight="700">一般</Text>
                    {/* TODO: 3末のリリースに合わせ権限表示を制御する */}
                    {PERMISSION_FEATURE_ADMIN_KEYS.filter((x) =>
                      permissionSettingList.includes(x),
                    ).map((permission, idx) => (
                      <Box
                        key={`permission_feature_${permission}_${String(idx)}`}
                      >
                        <Checkbox
                          name={`permission_feature_${permission}`}
                          isChecked={userPermissionList.includes(permission)}
                          onChange={() => onChangePermissionCheck(permission)}
                        >
                          {PERMISSION_FEATURES.get(permission)}
                        </Checkbox>
                      </Box>
                    ))}
                    {toErrMsgList(errors, 'permission').map((err) => (
                      <FormErrorMessage key={`permission${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(errors.userPools)}
                    display="grid"
                    gridGap="8px"
                  >
                    {userPoolPermissionList.map((userPool, idx) => (
                      <VStack
                        spacing={2}
                        mt="16px"
                        alignItems="flex-start"
                        key={`user_userPool_${String(idx)}`}
                      >
                        <Text fontWeight="700">{userPool.userPoolName}</Text>
                        <Checkbox
                          name={`userPool_permission_${userPool.userPoolId}_${CORPORATION_USER_POOL_PERMISSION_USER_POOL}`}
                          isChecked={userPool.permission.includes(
                            CORPORATION_USER_POOL_PERMISSION_USER_POOL,
                          )}
                          onChange={() =>
                            onChangeUserPoolPermissionCheck(
                              idx,
                              CORPORATION_USER_POOL_PERMISSION_USER_POOL,
                            )
                          }
                        >
                          IDシステム設定管理
                        </Checkbox>
                        <Checkbox
                          name={`userPool_permission_${userPool.userPoolId}_${CORPORATION_USER_POOL_PERMISSION_TENANT}`}
                          isChecked={userPool.permission.includes(
                            CORPORATION_USER_POOL_PERMISSION_TENANT,
                          )}
                          onChange={() =>
                            onChangeUserPoolPermissionCheck(
                              idx,
                              CORPORATION_USER_POOL_PERMISSION_TENANT,
                            )
                          }
                        >
                          テナント設定管理
                        </Checkbox>
                      </VStack>
                    ))}
                    {toErrMsgList(errors, 'userPools').map((err) => (
                      <FormErrorMessage key={`userPools${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </VStack>
              </Box>
            </Stack>
          </form>
        </DrawerBody>
        <DrawerFooter display="flex" justifyContent="end" gridGap={4}>
          <Button
            variant="outline"
            lineHeight={5}
            p="8px 24px"
            h="auto"
            onClick={() => onClose()}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            colorScheme="primary"
            lineHeight={5}
            p="8px 16px"
            h="auto"
            leftIcon={<MdEmail />}
          >
            招待メール送信
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
