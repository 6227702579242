import {
  PERMISSION_FEATURE_ADMIN_DASHBOARD,
  PERMISSION_FEATURE_ADMIN_SAML,
  PERMISSION_FEATURE_ADMIN_SERVICE,
  PERMISSION_FEATURE_ADMIN_SETTING,
  PERMISSION_FEATURE_ADMIN_USER,
  PERMISSION_FEATURE_ADMIN_USER_POOL
} from 'admin/define/permissions';
import { UserPoolType } from 'admin/types/user';
import { Corporation, Corporations, CorporationUserPool } from 'api/user/types';
import {
  AUTH_CODE_TYPE_OUTER,
  AUTH_CODE_TYPE_SAML,
  AUTH_CODE_TYPE_UNIIKEY,
  CORPORATION_USER_POOL_PERMISSION_TENANT,
  CORPORATION_USER_POOL_PERMISSION_USER_POOL
} from 'define';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userCorporationsState, userCorporationState } from 'store/user';

export const useUserCorporationInfo = (): {
  currentCorporation: Corporation | null;
  corporations: Corporations;
  userPoolIdWithSaml: CorporationUserPool['userPoolId'];
  currentUserPoolGroupId: Corporation['userPoolGroupId'];
  hasUserPoolGroupId: boolean;
  hasUserPoolAuthTypeWithUniikey: boolean;
  hasUserPoolAuthTypeWithOther: boolean;
  hasUserPoolAuthTypeWithSaml: boolean;
  hasPermissionDashboard: boolean;
  hasPermissionUser: boolean;
  hasPermissionTenantUser: boolean;
  hasPermissionSaml: boolean;
  hasPermissionSetting: boolean;
  hasPermissionUserPool: boolean;
  hasAuthUserPools: boolean;
  hasAuthTenantSerivce: boolean;
  isShowUserPool: (userPoolId: UserPoolType['userPoolId']) => boolean;
  isShowTenantService: (userPoolId: UserPoolType['userPoolId']) => boolean;
} => {
  const currentCorporation = useRecoilValue(userCorporationState);
  const corporations = useRecoilValue(userCorporationsState);

  /**
   * authenticationTypeがSAMLのユーザープールIDを取得
   * TODO: SAML設定の対応可否をuserPoolIdWithSamlで判定をしているが、
   * string型の為、判定材料の型としては不適切と思っています。
   * 将来的にリファクタリング対応が必要
   */
  const userPoolIdWithSaml = useMemo(() => {
    // 法人情報が存在しない場合
    if (!currentCorporation) return '';
    const target = currentCorporation.userPools.filter((x) => x.authenticationType === AUTH_CODE_TYPE_SAML);
    // 認証タイプSAMLのユーザープールが見つからなかった場合
    if (target.length === 0) return '';

    // 存在する場合は複数あっても配列最初のIDを返却する
    return target[0].userPoolId;
  },[currentCorporation]);

  const currentUserPoolGroupId = useMemo(() => {
    // 法人情報が存在しない場合
    if (!currentCorporation) return '';

    return currentCorporation.userPoolGroupId;
  }, [currentCorporation]);

  /**
   * 法人のユーザープールグループID設定があるかどうか
   */
  const hasUserPoolGroupId = useMemo(
    () => currentCorporation !== null && !!currentCorporation.userPoolGroupId,
    [currentCorporation],
  )

  /**
   * 法人がもつユーザープールの認証タイプに"uniikey"が含まれているかどうか
   */
  const hasUserPoolAuthTypeWithUniikey = useMemo(
    () => currentCorporation !== null && currentCorporation.userPools.some((x) => x.authenticationType === AUTH_CODE_TYPE_UNIIKEY),
    [currentCorporation],
  )

  /**
   * 法人がもつユーザープールの認証タイプに"外部連携"が含まれているかどうか
   */
  const hasUserPoolAuthTypeWithOther = useMemo(
    () => currentCorporation !== null && currentCorporation.userPools.some((x) => x.authenticationType === AUTH_CODE_TYPE_OUTER),
    [currentCorporation],
  )

  /**
   * 法人がもつユーザープールの認証タイプに"SAML"が含まれているかどうか
   */
  const hasUserPoolAuthTypeWithSaml = useMemo(
    () => currentCorporation !== null && currentCorporation.userPools.some((x) => x.authenticationType === AUTH_CODE_TYPE_SAML),
    [currentCorporation],
  )

  const hasPerMission = useMemo(
    () => currentCorporation !== null && Array.isArray(currentCorporation?.permission),
    [currentCorporation],
  );

  // 権限: ダッシュボード
  const hasPermissionDashboard = useMemo((): boolean => {
    if (hasPerMission) {
      return Boolean(
        currentCorporation?.permission.includes(PERMISSION_FEATURE_ADMIN_DASHBOARD),
      );
    }

    return false;
  }, [currentCorporation?.permission, hasPerMission]);

  // 権限: 組織アカウント管理
  const hasPermissionUser = useMemo((): boolean => {
    if (hasPerMission) {
      return Boolean(
        currentCorporation?.permission.includes(PERMISSION_FEATURE_ADMIN_USER),
      );
    }

    return false;
  }, [currentCorporation?.permission, hasPerMission]);

  // 権限: テナントアカウント管理（個別サービスアカウント管理）
  const hasPermissionTenantUser = useMemo((): boolean => {
    if (hasPerMission) {
      return Boolean(
        currentCorporation?.permission.includes(PERMISSION_FEATURE_ADMIN_SERVICE),
      );
    }

    return false;
  }, [currentCorporation?.permission, hasPerMission]);

  // 権限: SAML設定
  const hasPermissionSaml = useMemo((): boolean => {
    if (hasPerMission) {
      return Boolean(
        currentCorporation?.permission.includes(PERMISSION_FEATURE_ADMIN_SAML),
      );
    }

    return false;
  }, [currentCorporation?.permission, hasPerMission]);

  // 権限: 設定
  const hasPermissionSetting = useMemo((): boolean => {
    if (hasPerMission) {
      return Boolean(
        currentCorporation?.permission.includes(PERMISSION_FEATURE_ADMIN_SETTING),
      );
    }

    return false;
  }, [currentCorporation?.permission, hasPerMission]);

  // 権限: ユーザープール作成・編集・割り当て
  const hasPermissionUserPool = useMemo((): boolean => {
    if (hasPerMission) {
      return Boolean(
        currentCorporation?.permission.includes(PERMISSION_FEATURE_ADMIN_USER_POOL),
      );
    }

    return false;
  }, [currentCorporation?.permission, hasPerMission]);

  // 権限: IDシステム管理に関するユーザープールの権限があるかどうか全てのユーザープール検証
  const hasAuthUserPools = useMemo((): boolean => {
    const userPools = currentCorporation?.userPools;

    if (!userPools) return false;

    return hasPermissionUserPool || userPools.some((x) => x.permission.includes(CORPORATION_USER_POOL_PERMISSION_USER_POOL))
  }, [hasPermissionUserPool, currentCorporation?.userPools]);

  // 権限: IDシステム設定管理ページにおいて特定のユーザープールの編集権限があるかどうか
  const isShowUserPool = useCallback((userPoolId: UserPoolType['userPoolId']): boolean => {
    const userPools = currentCorporation?.userPools;

    return (userPools?.filter((x) =>
      x.userPoolId === userPoolId &&
      x.permission.includes(CORPORATION_USER_POOL_PERMISSION_USER_POOL)
    ) || []).length > 0
  }, [currentCorporation?.userPools]);

  // 権限: テナント設定管理に関するユーザープールの権限があるかどうか全てのユーザープール検証
  const hasAuthTenantSerivce = useMemo((): boolean => {
    const userPools = currentCorporation?.userPools;

    if (!userPools) return false;

    return hasPermissionUserPool || userPools.some((x) => x.permission.includes(CORPORATION_USER_POOL_PERMISSION_TENANT))
  }, [hasPermissionUserPool, currentCorporation?.userPools]);

  // 権限: テナント設定管理ページにおいて特定のユーザープールの編集権限があるかどうか
  const isShowTenantService = useCallback((userPoolId: UserPoolType['userPoolId']): boolean => {
    const userPools = currentCorporation?.userPools;

    return (userPools?.filter((x) =>
      x.userPoolId === userPoolId &&
      x.permission.includes(CORPORATION_USER_POOL_PERMISSION_TENANT)
    ) || []).length > 0
  }, [currentCorporation?.userPools]);

  return {
    currentCorporation,
    corporations,
    userPoolIdWithSaml,
    currentUserPoolGroupId,
    hasUserPoolGroupId,
    hasUserPoolAuthTypeWithUniikey,
    hasUserPoolAuthTypeWithOther,
    hasUserPoolAuthTypeWithSaml,
    hasPermissionDashboard,
    hasPermissionUser,
    hasPermissionTenantUser,
    hasPermissionSaml,
    hasPermissionSetting,
    hasPermissionUserPool,
    hasAuthUserPools,
    hasAuthTenantSerivce,
    isShowUserPool,
    isShowTenantService,
  };
};
