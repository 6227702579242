import { ButtonProps } from '@chakra-ui/react';
import { AlertInfoBtnColorSetType, AlertInfoColorSetType } from 'admin/types/ui/alertInfo';

export const Colors = {
  GRAY_03: '#272E35',
  GRAY_01: '#4A545E',
  GRAY_00: '#555F6D',
  GRAY_10: '#7E8B99',
  GRAY_05: '#727375',
  GRAY_15: '#9EA8B3',
  GRAY_20: '#AAAAAA',
  GRAY_30: '#CFD6DD',
  GRAY_40: '#DEE3E7',
  GRAY_50: '#E9ECEF',
  GRAY_60: '#E3E6EA',
  GRAY_65: '#EAEDF0',
  GRAY_70: '#F5F7F9',
  GRAY_75: '#EAEDF0',
  GRAY_80: '#f5f5f5',
  BLUE_LIGHT_01: '#D7E4FF',
  BLUE_00: '#113997',
  BLUE_10: '#1E50C0',
  BLUE_20: '#3062D4',
  BLUE_30: '#D7E4FF',
  RED: '#FF0000',
  RED_00: '#6F2020',
  RED_10: '#952D2D',
  RED_20: '#C53434',
  RED_30: '#FCD9D9',
  RED_40: '#FEF5F5',
  RED_50: '#FFFAFA',
  RED_60: '#EC0000',
  GREEN_00: '#0E4E30',
  GREEN_10: '#1D7C4D',
  GREEN_20: '#C6F1DA',
  ORANGE_00: '#7A4510',
  ORANGE_10: '#F59638',
  ORANGE_20: '#FCDEC0',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
} as const;

/**
 * chakra-ui ボタン用のカラーセット
 * ※Primary,Error以外のカラーセットは未調整
 */
export const ColorSet: { [key: string]: ButtonProps } = {
  Default: {
    color: Colors.GRAY_03,
    bgColor: Colors.WHITE,
    border: '1px solid',
    borderColor: Colors.GRAY_30,
    _hover: {
      bgColor: Colors.GRAY_70,
    },
    _active: {
      bgColor: Colors.GRAY_50,
    },
  },
  Primary: {
    color: Colors.WHITE,
    bgColor: Colors.BLUE_20,
    _hover: {
      bgColor: Colors.BLUE_10,
    },
    _active: {
      bgColor: Colors.BLUE_00,
    },
  },
  Secondary: {
    color: Colors.WHITE,
    bgColor: Colors.BLUE_20,
    _hover: {
      bgColor: Colors.BLUE_10,
    },
    _active: {
      bgColor: Colors.BLUE_00,
    },
  },
  Success: {
    color: Colors.WHITE,
    bgColor: Colors.BLUE_20,
    _hover: {
      bgColor: Colors.BLUE_10,
    },
    _active: {
      bgColor: Colors.BLUE_00,
    },
  },
  Info: {
    color: Colors.WHITE,
    bgColor: Colors.BLUE_20,
    _hover: {
      bgColor: Colors.BLUE_10,
    },
    _active: {
      bgColor: Colors.BLUE_00,
    },
  },
  Error: {
    color: Colors.WHITE,
    bgColor: Colors.RED_20,
    _hover: {
      bgColor: Colors.RED_10,
    },
    _active: {
      bgColor: Colors.RED_00,
    },
  },
  Warning: {
    color: Colors.WHITE,
    bgColor: Colors.RED_20,
    _hover: {
      bgColor: Colors.RED_10,
    },
    _active: {
      bgColor: Colors.RED_00,
    },
  },
};

// カラーコード定義はレイアウトfix後対応
export const AlertInfoColorSet: AlertInfoColorSetType = {
  error: {
    borderColor: '#F26464',
    bgColor: '#FFEBEB',
    color: '#C53434'
  },
  warning: {
    borderColor: '#F6A351',
    bgColor: '#FFF0E0',
    color: '#B4610E'
  },
  success: {
    borderColor: '#4AA578',
    bgColor: '#E6F9EF',
    color: '#1D7C4D'
  },
  info: {
    borderColor: '#6792F4',
    bgColor: '#EDF2FF',
    color: '#3062D4'
  },
}

// カラーコード定義はレイアウトfix後対応
export const AlertInfoBtnColorSet: AlertInfoBtnColorSetType = {
  error: {
    ...AlertInfoColorSet.error,
    _hover: {
      bgColor: Colors.RED_30,
    },
    _active: {
      bgColor: Colors.RED_30,
    },
  },
  warning: {
    ...AlertInfoColorSet.warning,
    _hover: {
      bgColor: Colors.ORANGE_20,
    },
    _active: {
      bgColor: Colors.ORANGE_20,
    },
  },
  success: {
    ...AlertInfoColorSet.success,
    _hover: {
      bgColor: Colors.GREEN_20,
    },
    _active: {
      bgColor: Colors.GREEN_20,
    },
  },
  info: {
    ...AlertInfoColorSet.info,
    _hover: {
      bgColor: Colors.BLUE_30,
    },
    _active: {
      bgColor: Colors.BLUE_30,
    },
  },
}
