import { Tenant, TenantMailList } from 'api/tenant/types';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTenant } from 'hooks/tenant/useTenant';

const tenantDefaultValue: Tenant = {
  id: '',
  name: '',
  apiKey: '',
  mailFromAddress: '',
  mailReplyAddress: '',
  loginUrl: '',
  mailmaga: [],
};

export const useTenantInfo = (): {
  tenant: Tenant;
  mailFromAddressList: TenantMailList[];
  mailReplyAddressList: TenantMailList[];
} => {
  const tenantId = useUserTenantId();
  const tenant = useTenant({ tenantId });

  // 差出人メールアドレスリスト作成
  const fromList =
    tenant?.mailFromAddress !== null
      ? tenant?.mailFromAddress.split('\n') || []
      : [];
  const mailFromAddressList: TenantMailList[] = [];
  fromList.forEach((item, idx) =>
    mailFromAddressList.push({
      id: idx,
      name: item,
    }),
  );

  // 返信用メールアドレスリスト作成
  const replyList =
    tenant?.mailReplyAddress !== null
      ? tenant?.mailReplyAddress.split('\n') || []
      : [];
  const mailReplyAddressList: TenantMailList[] = [];
  replyList.forEach((item, idx) =>
    mailReplyAddressList.push({
      id: idx,
      name: item,
    }),
  );

  return {
    tenant: tenant || tenantDefaultValue,
    mailFromAddressList,
    mailReplyAddressList,
  };
};
