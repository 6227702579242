import { getServiceRules } from 'admin/api/service/rule/getServiceRules';
import { CustomError } from 'admin/error/CustomError';
import { UserPool } from 'admin/types/userPool';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useServiceRules = (id: UserPool['id']) => {
  const toast = useCustomToast();

  const { data } = useQuery({
    queryKey: queryKeys.getServiceRules(id),
    queryFn: () => getServiceRules(id),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
        if (error.logLevel === 'warning') {
          toast({
            ...errorToast,
            duration: 4000,
            title: error.message,
            position: 'bottom',
            variant: 'solid',
          });
        }
      }
    },
  });

  return { data };
};
