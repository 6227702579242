import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { serviceAttributeGroupsSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeGroupsType } from 'admin/types/service/attribute';
import { DataWithPage } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getServiceAttributeGroups = async ({
  userPoolChildId,
  options,
}: {
  userPoolChildId: string;
  options?: Options;
}): Promise<DataWithPage<ServiceAttributeGroupsType>> => {
  const path = `uniikey/${userPoolChildId}/child/group_attribute/`;
  const response = await request<DataWithPage<ServiceAttributeGroupsType>>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.SERVICE.GET_SEVICE_ATTRIBUTE_GROUPS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.SERVICE.GET_SEVICE_ATTRIBUTE_GROUPS.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }
  try {
    serviceAttributeGroupsSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<ServiceAttributeGroupsType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
