import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { useMemo } from 'react';

export const useMenuPermission = (): {
  hasAllowedIdPoolConsumer: boolean;
  hasAllowedService: boolean;
  hasAllowedSaml: boolean;
  hasAllowedMember: boolean;
} => {
  const {
    hasUserPoolGroupId,
    hasUserPoolAuthTypeWithSaml,
    hasUserPoolAuthTypeWithUniikey,
    hasPermissionSaml,
    hasAuthUserPools,
    hasAuthTenantSerivce
  } = useUserCorporationInfo();

  /**
   * IDシステム管理設定ページメニュー表示制御
   */
  const hasAllowedIdPoolConsumer = useMemo<boolean>(
    () => hasUserPoolGroupId && hasAuthUserPools,
    [hasUserPoolGroupId, hasAuthUserPools]
  )

  /**
   * テナント管理ページメニュー表示制御
   */
  const hasAllowedService = useMemo<boolean>(
    () => hasUserPoolGroupId && hasAuthTenantSerivce,
    [hasUserPoolGroupId, hasAuthTenantSerivce]
  )

  /**
   * SAML設定ページメニュー表示制御
   */
  const hasAllowedSaml = useMemo<boolean>(
    () => hasUserPoolGroupId && hasUserPoolAuthTypeWithSaml && hasPermissionSaml,
    [hasUserPoolGroupId, hasUserPoolAuthTypeWithSaml, hasPermissionSaml]
  )

  /**
   * 会員管理ページメニュー表示制御
   */
  const hasAllowedMember = useMemo<boolean>(
    () => hasUserPoolGroupId && hasUserPoolAuthTypeWithUniikey,
    [hasUserPoolGroupId, hasUserPoolAuthTypeWithUniikey]
  )

  return {
    hasAllowedIdPoolConsumer,
    hasAllowedService,
    hasAllowedSaml,
    hasAllowedMember,
  }
}
