import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceSchema } from 'admin/schema/service';
import { ServicesType, ServiceType } from 'admin/types/service';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

const dummyData: ServicesType = [
  {
    id: 'dummy-service-id-1',
    name: 'テナントA',
    userPoolChildId: '',
    userPoolId: '',
    userPoolName: '',
  },
  {
    id: 'dummy-service-id-2',
    name: 'テナントB',
    userPoolChildId: '',
    userPoolId: 'dummy-id-2',
    userPoolName: '',
  },
  {
    id: 'dummy-service-id-3',
    name: 'テナントC',
    userPoolChildId: '',
    userPoolId: 'dummy-id-3',
    userPoolName: '',
  },
  {
    id: 'dummy-service-id-4',
    name: 'テナントD',
    userPoolChildId: '',
    userPoolId: 'dummy-id-4',
    userPoolName: '',
  },
];

export const getService = async (
  id: ServiceType['id'],
): Promise<ServiceType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<getIdPoolConsumersType>({
  //   path,
  //   method: 'get',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  // 検証の為下記実装を行う※本実装では不要の為削除
  const filterData: ServiceType = dummyData.filter((x) => x.id === id)[0];

  // ダミーデータ
  const response = await getDummySuccessResponse<ServiceType>(filterData);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SERVICE.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SERVICE.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    serviceSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
