import { getRules } from 'admin/api/userPool/rule/getRules';
import { CustomError } from 'admin/error/CustomError';
import { convertToPage } from 'admin/schema/page';
import { UserPool } from 'admin/types/userPool';
import { RulesType } from 'admin/types/userPool/rule/index';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useRules = (
  id: UserPool['id'],

  currentPage: Page['currentPage'],
) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeys.getRules(id, currentPage),
    queryFn: () => getRules(id, currentPage),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<RulesType>>(data),
    refetch,
    isLoading,
  };
};
