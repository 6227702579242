import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useEditRule } from 'admin/hooks/service/rule/useEditRule';
import { useServiceRule } from 'admin/hooks/service/rule/useServiceRule';
import { serviceRuleFormSchema } from 'admin/schema/service/rule';
import { ServiceRuleFormType } from 'admin/types/service/rule';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { RuleFormComponent } from './RuleFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
  ruleId: string;
};

const FetchRuleForm: FC<Props> = memo(({ onClose, formId, ruleId }: Props) => {
  const { data: ruleValue } = useServiceRule(ruleId);
  const { mutate, isLoading } = useEditRule();

  const methods = useForm<ServiceRuleFormType>({
    defaultValues: {
      id: ruleId,
      sortNumber: ruleValue?.sortNumber ? String(ruleValue?.sortNumber) : '',
      status: ruleValue?.status,
    },
    resolver: zodResolver(serviceRuleFormSchema),
  });

  const onSubmit: SubmitHandler<ServiceRuleFormType> = async (
    data: ServiceRuleFormType,
  ) => {
    await mutate(data);
    onClose();
  };

  if (!ruleValue) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <RuleFormComponent
        onSubmit={onSubmit}
        formId={formId}
        isLoading={isLoading}
        rule={ruleValue}
      />
    </FormProvider>
  );
});

export const RuleForm = memo(withSuspenseAndErrorBoundary(FetchRuleForm));
