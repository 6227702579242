import { customErrorMessage, toZod } from 'admin/config/zod';
import { attributeSchema } from 'admin/schema/idPoolConsumer/attribute';
import {
  CreateAttributeGroupFormType,
  EditAttributeGroupFormType,
} from 'admin/types/userPool/attributeGroup/form';
import {
  AttributeGroupBodyType,
  AttributeGroupType,
} from 'admin/types/userPool/attributeGroup/index';
import { z } from 'zod';
import { isPositiveInteger } from '../util/index';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const attributeGroupBodySchema = z.object<toZod<AttributeGroupBodyType>>(
  {
    id: z.number(),
    userAttributeSetting: attributeSchema.omit({
      codeGroup: true,
      status: true,
    }),
    attributeRole: z.string().nullable(),
    attributeGroupHead: z.string(),
  },
);

export const attributeGroupSchema = z.object<toZod<AttributeGroupType>>({
  id: z.string(),
  userPool: z.string(),
  isAutoAppendToChild: z.boolean(),
  status: z.boolean(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().nullable(),
  displayOrder: z.number(),
  displayType: z.string().min(1, '画面上の配置は必須です。'),
  attributeGroupBodyList: z.array(attributeGroupBodySchema),
  isFormAttr: z.boolean(),
});

export const attributeGroupsSchema = z.array(attributeGroupSchema);

export const createAttributeGroupSchema = z.object<
  toZod<CreateAttributeGroupFormType>
>({
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().nullable(),
  displayOrder: z
    .string()
    .optional()
    .refine((val) => isPositiveInteger(String(val)), {
      message: '正数値のみが許可されます。小数点以下は含められません。',
    }),
  displayType: z.string().min(1, '画面上の配置は必須です。'),
  attributeGroupBodyList: z
    .array(
      z.object({
        userAttributeSetting: z.string(),
        attributeRole: z.string().nullable(),
      }),
    )
    .superRefine((value, ctx) => {
      value.forEach((item, index) => {
        if (!item.attributeRole && item.userAttributeSetting === '') {
          ctx.addIssue({
            code: 'custom',
            message: '使用する属性は必須です',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
        if (
          item.attributeRole === 'zip_code_ja' &&
          item.userAttributeSetting === ''
        ) {
          ctx.addIssue({
            code: 'custom',
            message: '郵便番号は必須です。',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
        if (
          item.attributeRole === 'prefecture_ja' &&
          item.userAttributeSetting === ''
        ) {
          ctx.addIssue({
            code: 'custom',
            message: '都道府県は必須です。',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
        if (
          item.attributeRole === 'address1' &&
          item.userAttributeSetting === ''
        ) {
          ctx.addIssue({
            code: 'custom',
            message: '住所1は必須です。',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
      });
    }),
});

export const editAttributeGroupSchema = z.object<
  toZod<EditAttributeGroupFormType>
>({
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().nullable(),
  displayOrder: z
    .string()
    .optional()
    .refine((val) => isPositiveInteger(String(val)), {
      message: '正数値のみが許可されます。小数点以下は含められません。',
    }),
  displayType: z.string().min(1, '画面上の配置は必須です。'),
  status: z.boolean().optional(),
  isAutoAppendToChild: z.boolean().optional(),
  isFormAttr: z.boolean().optional(),
  attributeGroupBodyList: z
    .array(
      z.object({
        userAttributeSetting: z.string().nullable(),
        attributeRole: z.string().nullable(),
      }),
    )
    .superRefine((value, ctx) => {
      value.forEach((item, index) => {
        if (!item.attributeRole && item.userAttributeSetting === '') {
          ctx.addIssue({
            code: 'custom',
            message: '使用する属性は必須です',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
        if (
          item.attributeRole === 'zip_code_ja' &&
          item.userAttributeSetting === ''
        ) {
          ctx.addIssue({
            code: 'custom',
            message: '郵便番号は必須です。',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
        if (
          item.attributeRole === 'prefecture_ja' &&
          item.userAttributeSetting === ''
        ) {
          ctx.addIssue({
            code: 'custom',
            message: '都道府県は必須です。',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
        if (
          item.attributeRole === 'address1' &&
          item.userAttributeSetting === ''
        ) {
          ctx.addIssue({
            code: 'custom',
            message: '住所1は必須です。',
            path: [index, 'userAttributeSetting'],
            fatal: true,
          });
        }
      });
    }),
});
