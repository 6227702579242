import { useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { RuleDrawer } from 'admin/components/service/rule/ruleDrawer/index';
import { useServiceRules } from 'admin/hooks/service/rule/useServiceRules';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RuleTableComponent } from './RuleTableComponent';

const fetchRuleTable: FC = memo(() => {
  const params = useParams();
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure();

  const [ruleId, setRuleId] = useState<string>('');

  const formId = `serviceRuleEditForm${ruleId}`;
  const serviceId = useMemo(() => params.serviceId ?? '', [params]);
  const { data: rules } = useServiceRules(serviceId);

  if (!rules) {
    return null;
  }

  return (
    <>
      <RuleTableComponent
        rules={rules}
        onDrawerOpen={onDrawerOpen}
        setRuleId={setRuleId}
      />
      <RuleDrawer
        ruleId={ruleId}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        formId={formId}
      />
    </>
  );
});

export const RuleTable = memo(
  withSuspenseAndErrorBoundary(fetchRuleTable, {
    ErrorComponent: <ErrorContents name="規約一覧" />,
  }),
);
