import { AlertDialog, Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeEditDrawer } from 'admin/components/idPoolConsumer/attribute/attributeDrawer';
import { AttributeTableComponent } from 'admin/components/idPoolConsumer/attribute/attributeTable/AttributeTableComponent';
import { AutoAppendDialog } from 'admin/components/idPoolConsumer/attribute/autoAppendDialog';
import { FormAttrDialog } from 'admin/components/idPoolConsumer/attribute/formAttrDialog';
import { StatusChangeDialog } from 'admin/components/idPoolConsumer/attribute/statusChangeDialog';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useIdPoolConsumerAttributes } from 'admin/hooks/userPool/attribute/useIdPoolConsumerAttributes';
import { useUserPoolAuthenticationType } from 'admin/hooks/userPool/useUserPoolAuthenticationType';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = {
  currentPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchAtributeTable: FC<Props> = memo(
  ({ currentPage, setPage }: Props) => {
    const [attributeId, setAttributeId] =
      useState<IdPoolConsumerAttributeType['id']>('');
    const [targetAttribute, setTargetAttribute] =
      useState<IdPoolConsumerAttributeType>();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const idPoolConsumerId = useIdPoolConsumerId();

    const { data: attributes, page } = useIdPoolConsumerAttributes(
      idPoolConsumerId,
      currentPage,
    );
    const cancelRef = useRef(null);

    const { isOuter } = useUserPoolAuthenticationType();
    const {
      isOpen: isOpenStatusDialog,
      onClose: onCloseStatusDialog,
      onOpen: onOpenStatusDialog,
    } = useDisclosure();
    const {
      isOpen: isOpenAutoAppendDialog,
      onClose: onCloseAutoAppendDialog,
      onOpen: onOpenAutoAppendDialog,
    } = useDisclosure();

    const {
      isOpen: isOpenFormAttrdDialog,
      onClose: onCloseFormAttrDialog,
      onOpen: onOpenFormAttrDialog,
    } = useDisclosure();

    const onOpenDrawer = useCallback(
      (id: IdPoolConsumerAttributeType['id']) => {
        setAttributeId(id);
        onOpen();
      },
      [onOpen],
    );

    useEffect(() => {
      setPage(page);
    }, [page, setPage]);

    if (!attributes) {
      return null;
    }

    return (
      <>
        <AttributeTableComponent
          attributes={attributes}
          isExternalId={isOuter}
          onOpen={onOpenDrawer}
          setTargetAttribute={setTargetAttribute}
          onOpenStatusDialog={onOpenStatusDialog}
          onOpenAutoAppendDialog={onOpenAutoAppendDialog}
          onOpenFormAttrDialog={onOpenFormAttrDialog}
        />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size="lg"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <AttributeEditDrawer id={attributeId} onClose={onClose} />
        </Drawer>
        <AlertDialog
          isOpen={isOpenStatusDialog}
          onClose={onCloseStatusDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <StatusChangeDialog
            onClose={onCloseStatusDialog}
            currentPage={currentPage}
            target={targetAttribute}
          />
        </AlertDialog>
        <AlertDialog
          isOpen={isOpenAutoAppendDialog}
          onClose={onCloseAutoAppendDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <AutoAppendDialog
            onClose={onCloseAutoAppendDialog}
            currentPage={currentPage}
            target={targetAttribute}
          />
        </AlertDialog>
        <AlertDialog
          isOpen={isOpenFormAttrdDialog}
          onClose={onCloseFormAttrDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <FormAttrDialog
            onClose={onCloseFormAttrDialog}
            currentPage={currentPage}
            target={targetAttribute}
          />
        </AlertDialog>
      </>
    );
  },
);

export const FetchAtributeTable = withSuspenseAndErrorBoundary(
  fetchAtributeTable,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性一覧" />,
  },
);

export const AttributeTable = memo(
  withSuspenseAndErrorBoundary(fetchAtributeTable, {
    ErrorComponent: <ErrorContents name="組織レベル属性一覧" />,
  }),
);
