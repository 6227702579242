import { zodResolver } from '@hookform/resolvers/zod';
import { useEditUserPool } from 'admin/hooks/userPool/useEditUserPool';
import { idPoolConsumerFormSchema } from 'admin/schema/idPoolConsumer';
import { UserPool } from 'admin/types/userPool';
import { EditIdPoolConsumerFormType } from 'admin/types/userPool/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { EditFormComponent } from './editFormComponent';

type Props = {
  userPool?: UserPool;
  onClose: () => void;
};

export const IdPoolConsumerEditForm: FC<Props> = memo(
  ({ userPool, onClose }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const methods = useForm<EditIdPoolConsumerFormType>({
      defaultValues: {
        id: '',
        name: '',
      },
      resolver: zodResolver(idPoolConsumerFormSchema),
    });

    const { setValue, setError } = methods;

    const { mutate, isLoading } = useEditUserPool(
      setError,
      setGlobalErrors
    );

    useEffect(() => {
      if (userPool) {
        setValue('id', userPool.id);
        setValue('name', userPool.name);
      }
    }, [setValue, userPool]);

    const onSubmit: SubmitHandler<EditIdPoolConsumerFormType> = async (
      data: EditIdPoolConsumerFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <EditFormComponent
          userPool={userPool}
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);
