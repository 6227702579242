import { CopyIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormLabel,
  Text
} from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { useCopy } from 'hooks/useCopy';
import React from 'react';

interface Props {
  subject: string;
  tagExample: string;
  items?: { tag: string; caption: string }[];
  children: React.ReactNode;
}

// 埋め込みを表示するためにinputタグなどを囲うラッパー
export const EmbeddedWrapper: React.FC<Props> = ({
  subject,
  items,
  children,
  tagExample,
}) => {
  const { copy } = useCopy();

  const handleClick = (tag: string) => () => {
    copy(`%%${tag}%%`);
  };

  // 配列に値が存在するときのみアコーディオンを表示する
  const isShowTags = items?.length !== 0;

  return (
    <Box alignItems="center" fontFamily="Hiragino Sans">
      <FormLabel mb={2} width="10%">
        <Text fontSize="14px" fontWeight="700">
          {subject}
        </Text>
      </FormLabel>

      <Box width="100%">
        {children}
        {isShowTags && (
          <>
            <Accordion allowMultiple>
              <AccordionItem
                border="1px solid #CFD6DD"
                borderRadius="6px"
                mt="8px"
              >
                <AccordionButton backgroundColor="#F5F7F9" role="button">
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={11}
                    display="flex"
                    alignItems="center"
                    color={Colors.GRAY_00}
                  >
                    <InfoOutlineIcon mr={2} w="18px" h="18px" />
                    <Text fontSize="14px" fontWeight="400" lineHeight="150%">
                      埋め込みワードについて
                    </Text>
                    <AccordionIcon ml={1} w="17px" h="17px" />
                  </Box>
                </AccordionButton>
                <AccordionPanel px={2} py={1}>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="150%"
                    color={Colors.GRAY_00}
                    my={2}
                    role="note"
                  >
                    {subject}に注文情報や{tagExample}
                    を埋め込むことができます。ラベルをクリックして差し込みワードをコピーし、
                    {subject}に貼り付けてください。
                  </Text>
                  <Box>
                    {items?.map((v, index) => (
                      <Button
                        role="option"
                        backgroundColor="#E5EEFF"
                        size="xs"
                        mr={2}
                        mb={2}
                        onClick={handleClick(v.tag)}
                        key={index.toString()}
                      >
                        {v.caption}
                        <CopyIcon ml={1} />
                      </Button>
                    ))}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        )}
      </Box>
    </Box>
  );
};

EmbeddedWrapper.defaultProps = {
  items: [],
};
