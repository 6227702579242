import { Card, CardHeader } from 'components/paywall/features/Card';
import { CourseCardHeader } from 'components/paywall/pages/CourseList/CourseCardHeader';
import { CourseCardItems } from 'components/paywall/pages/CourseList/CourseCardItems';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { FC, memo } from 'react';

type Props = {
  course: Course;
}

export const CourseCard: FC<Props> = memo(({course}: Props) => (
  <Card>
    <CardHeader padding="8px 24px">
      <CourseCardHeader course={course} />
    </CardHeader>
    <CourseCardItems course={course} />
  </Card>
));
