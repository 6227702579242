import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Link } from 'components/paywall/atoms/Link';
import { usePaywallAuth } from 'components/paywall/hooks/usePaywallAuth';
import { FC, Fragment, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { paywallSidebarState } from 'store/paywallSidebarState';

const StyledLinkContainer = styled(Box)({
  width: '100%',
  fontSize: 14,
});

const StyledItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ isSelected }) => ({
  width: 'calc(100% - 4px)',
  padding: 10,
  margin: 2,
  marginRight: 20,
  borderRadius: 5,
  '&:hover': {
    background: '#eeeeee',
    cursor: 'pointer',
  },
  backgroundColor: isSelected ? '#a9f2e94D' : '', // #a9f2e9
}));

export const Sidebar: FC = memo(() => {
  const [isOpen] = useRecoilState(paywallSidebarState);
  const location = useLocation();
  const { isAllowedPermission } = usePaywallAuth();
  const menuList = [
    {
      key: 'dashboard',
      name: 'ダッシュボード',
      path: '/paywall/dashboard',
      isArrowed: isAllowedPermission('dashboard'),
    },
    {
      key: 'wallList',
      name: 'サブスクリプション商品設定',
      path: '/paywall/wallList',
      child1: '/paywall/wallDesign',
      child2: '/paywall/courseList',
      child3: '/paywall/promotionList',
      isArrowed: isAllowedPermission('wallList'),
    },
    {
      key: 'itemList',
      name: '単品商品設定',
      path: '/paywall/itemList',
      isArrowed: isAllowedPermission('itemList'),
    },
    {
      key: 'MembersOnlyWallSetting',
      name: '会員限定ウォール設定',
      path: '/paywall/membersOnlyWallSetting',
      isArrowed: isAllowedPermission('membersOnlyWallSetting'),
    },
    {
      key: 'CustomerList',
      name: '顧客一覧',
      path: '/paywall/customerList',
      childKey: 'customerDetail',
      isArrowed: isAllowedPermission('customerList'),
    },
    {
      key: 'OrderList',
      name: '決済一覧',
      path: '/paywall/orderList',
      childKey: 'orderDetail',
      isArrowed: isAllowedPermission('orderList'),
    },
    {
      key: 'SubscriptionList',
      name: 'サブスクリプション注文一覧',
      path: '/paywall/subscriptionList',
      childKey: 'subscriptionDetail',
      isArrowed: isAllowedPermission('subscriptionList'),
    },
    {
      key: 'mail',
      name: 'ペイウォールメール設定',
      path: '/paywall/mail',
      isArrowed: isAllowedPermission('mail'),
    },
    {
      key: 'TermsSetting',
      name: '規約設定',
      path: '/paywall/terms',
      isArrowed: isAllowedPermission('terms'),
    },
  ];

  return (
    <Box
      position="fixed"
      width="234px"
      height="calc(100vh - 104px)"
      borderRight="1px solid rgba(102,153,254, 0.5)"
      zIndex="10"
      bgColor="white"
      left={isOpen ? '74px' : '-174px'}
      overflow="hidden"
      transition='all .2s ease'
    >
      <Flex>
        <StyledLinkContainer>
          {menuList.map((item) => (
            <Fragment key={item.key}>
              {item.isArrowed && (
                <Link to={item.path}>
                  <StyledItem
                    isSelected={
                      (item.childKey &&
                        location.pathname.indexOf(item.childKey) >= 1) ||
                      location.pathname === item.path ||
                      location.pathname === item.child1 ||
                      location.pathname === item.child2 ||
                      location.pathname === item.child3
                    }
                  >
                    {item.name}
                  </StyledItem>
                </Link>
              )}
            </Fragment>
          ))}
        </StyledLinkContainer>
      </Flex>
    </Box>
  );
});
