import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  serviceName: string;
};

export const RuleTitle: FC<Props> = memo(({ serviceName }: Props) => (
  <Box mt="40px">
    <Flex justifyContent="flex-end">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          {serviceName}
        </Text>
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          規約設定
        </Text>
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="170%"
          fontFamily="Hiragino Sans"
          color={Colors.GRAY_00}
        >
          規約についての説明
        </Text>
      </Box>
    </Flex>
  </Box>
));
