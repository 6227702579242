import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { RuleForm } from 'admin/components/service/rule/ruleForm';
import { ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type RuleDrawerProps = {
  onClose: () => void;
  formId: string;
  isOpen: boolean;
  ruleId: string;
};

export const RuleDrawer: FC<RuleDrawerProps> = memo(
  ({ onClose, formId, isOpen, ruleId }) => (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderWidth="1px" pb="24px">
          <Text>規約設定</Text>
        </DrawerHeader>
        <RuleForm ruleId={ruleId} formId={formId} onClose={onClose} />
        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorSchema="primary"
          >
            保存
          </Button>
        </DrawerFooter>
        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  ),
);
