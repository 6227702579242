import {
  Box,
  ListItem,
  Text,
  Tr as ChakraTr,
  UnorderedList,
} from '@chakra-ui/react';
import { AttributeTableBody } from 'admin/components/service/attribute/attributeTableBody';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import {
  Table,
  Tbody,
  Th,
  Thead,
  ThWithBorder,
} from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import {
  ServiceAttributesType,
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attributes: ServiceAttributesType;
  onOpen: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenFormAttrDialog: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenChildAttrDialog: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
};

export const AttributeTableComponent: FC<Props> = memo(
  ({
    attributes,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
  }: Props) => (
    <Box>
      <Table w="100%" maxHeight="calc(100vh - 420px)">
        <Thead border="none">
          <ChakraTr border="none">
            <Th
              maxWidth="130px"
              minWidth="130px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
              borderRightStyle="none"
              borderRightWidth="0"
              borderRight="none"
            >
              属性ID
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
              borderRightStyle="none"
              borderRightWidth="0"
              borderLeftStyle="none"
              borderLeftWidth="0"
            >
              表示名（日本語）
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              表示名（外国語）
            </Th>
            {/* ユーザープールIDがuniikeyの場合に追加する */}
            {/* <Th
            maxWidth="136px"
            minWidth="136px"
            fontSize="14px"
            fontWeight="600"
            p="16px"
          >
            会員登録時利用
          </Th>
          <Th
            maxWidth="136px"
            minWidth="136px"
            fontSize="14px"
            fontWeight="600"
            p="16px"
          >
            会員登録時必須
          </Th> */}
            <Th
              maxWidth="144px"
              minWidth="144px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              フィールド名
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="120px"
              minWidth="120px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              UIタイプ
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="120px"
              minWidth="120px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              データ型
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="144px"
              minWidth="144px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              選択肢マスタ
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="100px"
              minWidth="100px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              個人情報
            </Th>
            <ThWithBorder
              maxWidth="210px"
              minWidth="210px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              JSONパス
            </ThWithBorder>
            <Th
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              テナント割当
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性が操作中のテナントで利用可能になります。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            <ThWithBorder
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              フォームで利用
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性が操作中のテナントのフォームで表示されます。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </ThWithBorder>
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributes.map((attribute, idx) => (
            <AttributeTableBody
              attribute={attribute}
              onOpen={onOpen}
              onOpenFormAttrDialog={onOpenFormAttrDialog}
              onOpenChildAttrDialog={onOpenChildAttrDialog}
              key={`attribute-table-body_${String(idx)}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
