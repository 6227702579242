import {
  Box,
  DrawerBody,
  FormControl,
  FormErrorMessage,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { RhfCheckbox } from 'admin/components/form/RhfCheckbox';
import { RhfInput } from 'admin/components/form/RhfInput';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors } from 'admin/define/colors';
import { ServiceRuleFormType, ServiceRuleType } from 'admin/types/service/rule';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type RuleFormComponentProps = {
  formId: string;
  onSubmit: (value: ServiceRuleFormType) => void;
  isLoading: boolean;
  rule: ServiceRuleType;
};
export const RuleFormComponent: FC<RuleFormComponentProps> = memo(
  ({ onSubmit, formId, isLoading, rule }) => {
    const { handleSubmit } = useFormContext<ServiceRuleFormType>();
    const { errors } = useFormState<ServiceRuleFormType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  規約ID
                </Heading>
                <Box>{rule.id}</Box>
              </Box>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  表示名
                </Heading>
                <Box>日本語: {rule.displayEnName}</Box>
                <Box>外国語: {rule.displayEnName}</Box>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.sortNumber?.message)}>
                  <Heading as="h3" size="sm" mb={2}>
                    並び順
                  </Heading>
                  <Box>
                    <RhfInput<ServiceRuleFormType>
                      name="sortNumber"
                      bgColor={Colors.WHITE}
                    />
                  </Box>
                  <FormErrorMessage>
                    {errors.sortNumber?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  ステータス
                </Heading>
                <Box>
                  <RhfCheckbox<ServiceRuleFormType> name="status">
                    有効にする
                  </RhfCheckbox>
                </Box>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
      </>
    );
  },
);
