import { AlertDialog, Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ChildAttrDialog } from 'admin/components/service/attribute/attributeDialog/childAttrDialog';
import { FormAttrDialog } from 'admin/components/service/attribute/attributeDialog/formAttrDialog';
import { AttributeDrawer } from 'admin/components/service/attribute/attributeDrawer';
import { AttributeTableComponent } from 'admin/components/service/attribute/attributeTable/AttributeTableComponent';
import { Pagination } from 'admin/components/ui/pagination';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { useServiceAttributes } from 'admin/hooks/service/attribute/useServiceAttributes';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type fetchAttributeTableProps = {
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const CurrentSelectedNum = 0;

const fetchAttributeTable: FC<fetchAttributeTableProps> = memo(
  ({ currentPage, setPage, perPage }: fetchAttributeTableProps) => {
    const [attributeId, setAttributeId] =
      useState<UserPoolChildAttributeType['id']>('');
    const [selectedAttribute, setSelectedAttribute] =
      useState<ServiceAttributeType | null>(null);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
      isOpen: isOpenFormAttrDialog,
      onClose: onCloseFormAttrDialog,
      onOpen: onOpenFormAttrDialog,
    } = useDisclosure();
    const {
      isOpen: isOpenChildAttrDialog,
      onClose: onCloseChildAttrDialog,
      onOpen: onOpenChildAttrDialog,
    } = useDisclosure();
    const cancelRef = useRef(null);

    const { data: attributes, page } = useServiceAttributes(
      currentPage,
      perPage,
    );

    const openHandler = useCallback(
      (
        open: () => void,
        id: ServiceAttributeType['id'],
        userPoolChildAttributeId: UserPoolChildAttributeType['id'],
      ) => {
        setAttributeId(userPoolChildAttributeId);

        const filterAttributes = attributes?.filter((x) => x.id === id);
        setSelectedAttribute(
          !filterAttributes || filterAttributes?.length === 0
            ? null
            : filterAttributes[0],
        );
        open();
      },
      [attributes],
    );

    const onOpenDrawer = useCallback(
      (
        id: ServiceAttributeType['id'],
        userPoolChildAttributeId: UserPoolChildAttributeType['id'],
      ) => {
        openHandler(onOpen, id, userPoolChildAttributeId);
      },
      [onOpen, openHandler],
    );

    const onOpenFormAttrDialogHanlder = useCallback(
      (
        id: ServiceAttributeType['id'],
        userPoolChildAttributeId: UserPoolChildAttributeType['id'],
      ) => {
        openHandler(onOpenFormAttrDialog, id, userPoolChildAttributeId);
      },
      [onOpenFormAttrDialog, openHandler],
    );

    const onOpenChildAttrDialogHandler = useCallback(
      (
        id: ServiceAttributeType['id'],
        userPoolChildAttributeId: UserPoolChildAttributeType['id'],
      ) => {
        openHandler(onOpenChildAttrDialog, id, userPoolChildAttributeId);
      },
      [onOpenChildAttrDialog, openHandler],
    );

    useEffect(() => {
      setPage(page);
    }, [page, setPage]);

    if (!attributes) {
      return null;
    }

    return (
      <>
        <AttributeTableComponent
          attributes={attributes}
          onOpen={onOpenDrawer}
          onOpenFormAttrDialog={onOpenFormAttrDialogHanlder}
          onOpenChildAttrDialog={onOpenChildAttrDialogHandler}
        />
        <AlertDialog
          isOpen={isOpenFormAttrDialog}
          onClose={onCloseFormAttrDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <FormAttrDialog
            target={selectedAttribute}
            id={attributeId}
            onClose={onCloseFormAttrDialog}
          />
        </AlertDialog>
        <AlertDialog
          isOpen={isOpenChildAttrDialog}
          onClose={onCloseChildAttrDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <ChildAttrDialog
            target={selectedAttribute}
            id={attributeId}
            onClose={onCloseChildAttrDialog}
          />
        </AlertDialog>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size="md"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <AttributeDrawer
            serviceAttribute={selectedAttribute}
            id={attributeId}
            onClose={onClose}
          />
        </Drawer>
      </>
    );
  },
);

const AttributeTableInner = memo(
  withSuspenseAndErrorBoundary(fetchAttributeTable, {
    ErrorComponent: <ErrorContents name="属性一覧" />,
  }),
);

type Props = {
  selectedTab: number;
};

const AttributeTableWrap: FC<Props> = memo(({ selectedTab }: Props) => {
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 1,
    countFrom: 0,
    countTo: 0,
    perPage: 50,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  if (CurrentSelectedNum !== selectedTab) {
    return null;
  }

  return (
    <>
      <AttributeTableInner
        setPage={setPage}
        currentPage={currentPage}
        perPage={perPage}
      />
      <Pagination page={page} />
    </>
  );
});

export const AttributeTable = memo(
  withSuspenseAndErrorBoundary(AttributeTableWrap, {
    ErrorComponent: <ErrorContents name="属性一覧" />,
  }),
);
