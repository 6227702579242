import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useChangeCommonUse } from 'admin/hooks/userPool/rule';
import { agreeFormSchema } from 'admin/schema/idPoolConsumer/rule';
import { RuleType } from 'admin/types/userPool/rule';
import { AgreeFormType } from 'admin/types/userPool/rule/form';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CommonUseModalComponent } from './CommonUseModalComponent';

type FetchCommonUseModal = {
  onClose: () => void;
  rule: RuleType | null;
  currentPage: number;
};

const FetchCommonUseModal: FC<FetchCommonUseModal> = memo(
  ({ onClose, rule, currentPage }) => {
    const idPoolConsumerId = useIdPoolConsumerId();
    const { mutate, isLoading } = useChangeCommonUse(
      idPoolConsumerId,
      currentPage,
    );
    const methods = useForm<AgreeFormType>({
      defaultValues: {
        message: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(agreeFormSchema),
    });

    const onSubmit: SubmitHandler<AgreeFormType> = async () => {
      await mutate(rule?.id ?? '');
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <CommonUseModalComponent
          onClose={onClose}
          onSubmit={onSubmit}
          rule={rule}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);

export const CommonUseModal = withSuspenseAndErrorBoundary(FetchCommonUseModal);
