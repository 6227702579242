import { Box, Stack, useDisclosure, VStack } from '@chakra-ui/react';
import { DetailFormGroups } from 'admin/components/idPoolConsumer/formGroup/detail/detailFormGroups';
import { DetailHeader } from 'admin/components/idPoolConsumer/formGroup/detail/detailHeader';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useCreateFormGroupDetail } from 'admin/hooks/userPool/formGroup/useCreateFormGroupsDetail';
import { useEditFormGroupDetail } from 'admin/hooks/userPool/formGroup/useEditFormGroupDetail';
import { useFormGroupsDetail } from 'admin/hooks/userPool/formGroup/useFormGroupsDetail';
import { FormGroupDetailType } from 'admin/types/userPool/formGroup';
import { EnqueteFormType } from 'api/enquete/types';
import { ChangeLocationDialog } from 'components/common/atoms';
import { useBrowserBackControl } from 'hooks/useBrowserBackControl';
import {
  FC,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { randStr } from 'utils/str';

export const DetailInner: FC = memo(() => {
  const [copySelectType, setCopySelectType] = useState('');
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const formId = 'formGroupDetail';
  const params = useParams();
  const formGroupId = useMemo(() => params.formGroupId ?? '', [params]);
  const isEdit = useMemo<boolean>(() => {
    if (!formGroupId) return false;

    return true;
  }, [formGroupId]);

  const { data: formGroupDetail } = useFormGroupsDetail(formGroupId, isEdit);

  const methods = useForm<FormGroupDetailType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      id: '',
      name: '',
      groupUnits: [],
    },
  });

  const {
    control,
    setValue,
    getValues,
    setError,
    formState: { isDirty },
  } = methods;

  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateFormGroupDetail({ setError });
  const { mutate: editMutate, isLoading: isEditLoading } =
    useEditFormGroupDetail({ setError });
  const isLoading = useMemo(
    () => isCreateLoading || isEditLoading,
    [isCreateLoading, isEditLoading],
  );

  // 編集中かどうかをstateで管理
  const [isEdited, setIsEdited] = useState(false);

  // ブラウザバック制御
  useBrowserBackControl({ isEdited, onOpen: onOpenConfirm });

  const { fields, append, remove, move, swap, insert } = useFieldArray({
    control,
    name: 'groupUnits',
    keyName: 'key',
  });

  /**
   * 修正が加えられたかどうか
   */
  useEffect(() => {
    if (isDirty) setIsEdited(true);
  }, [isDirty]);

  // ドロップ時の処理
  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  // dndIdのユニーク値生成
  const uniqueId = useCallback(() => {
    // dndIdのユニーク値生成のための再帰処理
    const uid = () => {
      const str = randStr();
      if (fields.some((form) => form.dndId === str)) uid();

      return str;
    };

    return uid();
  }, [fields]);

  useEffect(() => {
    if (isEdit) {
      const tmpFormGroupDetailList = (formGroupDetail?.groupUnits || []).map(
        (x) => ({
          ...x,
          dndId: uniqueId(),
        }),
      );
      setValue('id', formGroupDetail?.id || '');
      setValue('groupUnits', tmpFormGroupDetailList);
      setValue('name', formGroupDetail?.name || '');
    } else {
      // 新規作成時
      append({
        dndId: `${uniqueId()}`,
        type: '',
      });
    }
  }, [append, formGroupDetail, isEdit]);

  const onInsertElement = useCallback(
    (index: number, enqueteContents: EnqueteFormType) => {
      insert(index, {
        ...enqueteContents,
        dndId: uniqueId(),
      });
    },
    [insert],
  );

  // 追加ボタン押下時の処理 (末尾)
  const onAddElement = useCallback(() => {
    append({
      dndId: uniqueId(),
      type: '',
    });
  }, [append, uniqueId]);

  // 追加ボタン押下時の処理 (項目の下に追加)
  const onAdd = useCallback(
    (index: number) => {
      insert(index + 1, { dndId: uniqueId(), type: '' });
    },
    [insert, uniqueId],
  );

  // 削除ボタン押下時の処理
  const onDeleteElement = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  // 上へボタン押下時の処理
  const onUp = useCallback(
    (
      index: number,
      isFirst: boolean,
      curRef: RefObject<HTMLDivElement>,
      prevRef: RefObject<HTMLDivElement> | null,
    ) => {
      if (isFirst) return;

      swap(index, index - 1);

      const rect1 = curRef.current?.getBoundingClientRect();
      const rect2 = prevRef?.current?.getBoundingClientRect();
      if (!rect1 || !rect2) return;

      const moveY = rect2.top - rect1.top;
      window.scrollBy(0, moveY);
    },
    [swap],
  );

  // 下へボタン押下時の処理
  const onDown = useCallback(
    (
      index: number,
      isLast: boolean,
      curRef: RefObject<HTMLDivElement>,
      nextRef: RefObject<HTMLDivElement> | null,
    ) => {
      if (isLast) return;

      swap(index, index + 1);

      const rect1 = curRef.current?.getBoundingClientRect();
      const rect2 = nextRef?.current?.getBoundingClientRect();
      if (!rect1 || !rect2) return;

      const moveY = rect2.bottom - rect1.bottom;
      window.scrollBy(0, moveY);
    },
    [swap],
  );

  const onCopy = useCallback(
    (index: number) => {
      const enquetes = getValues('groupUnits');
      const newFields: EnqueteFormType = {
        ...enquetes[index],
      };
      delete newFields.id;
      newFields.dndId = uniqueId();
      setCopySelectType(newFields.type);
      insert(index + 1, newFields);
    },
    [insert, getValues, uniqueId],
  );

  const onSubmit = async (data: FormGroupDetailType) => {
    const { groupUnits } = data;

    // 設問グループが一つも存在しないときはsubmitさせない
    if (groupUnits.length === 0) return;

    const submitGroupUnits: EnqueteFormType[] = groupUnits.map((v) => {
      const item = { ...v };
      delete item.typeOrUserAttrId;

      return item;
    });

    const submitForm: FormGroupDetailType = {
      ...data,
      groupUnits: submitGroupUnits,
    };

    if (isEdit) {
      await editMutate(submitForm);

      return;
    }
    await createMutate(submitForm);
  };

  return (
    <Stack spacing={0}>
      <Box>
        <FormProvider {...methods}>
          {isLoading && <LoadingLayer />}
          <VStack spacing={4} flex={1} pb={10} alignItems="flex-start">
            <DetailHeader formId={formId} />
            <DetailFormGroups
              list={fields}
              handleDragEnd={handleDragEnd}
              copySelectType={copySelectType}
              formId={formId}
              onUp={onUp}
              onDown={onDown}
              onAdd={onAdd}
              onCopy={onCopy}
              onDelete={onDeleteElement}
              onAddElement={onAddElement}
              onInsertElement={onInsertElement}
              apiData={formGroupDetail?.groupUnits || []}
              isThanksFlg={false}
              setIsEdited={setIsEdited}
              onSubmit={onSubmit}
              uniqueId={uniqueId}
            />
          </VStack>
        </FormProvider>
        <ChangeLocationDialog
          isOpen={isOpenConfirm}
          onClose={onCloseConfirm}
          setIsEdited={setIsEdited}
        />
      </Box>
    </Stack>
  );
});
