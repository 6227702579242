import { Button, Flex, Text } from '@chakra-ui/react';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, TdWithBorder, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import {
  getDataTypeNameByValue,
  getUiTypeNameByValue,
} from 'admin/hooks/userPool/attribute/useFilterFieldTypeList';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attribute: ServiceAttributeType;
  onOpen: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenFormAttrDialog: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenChildAttrDialog: (
    id: ServiceAttributeType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  codeGroupName: (id: string | null | undefined) => string | null | undefined;
};

export const AttributeTableBodyComponent: FC<Props> = memo(
  ({
    attribute,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
    codeGroupName,
  }: Props) => (
    <Tr>
      <Td>
        <Text
          fontSize="14px"
          fontWeight="700"
          color={Colors.BLUE_20}
          _hover={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() =>
            onOpen(attribute.id, attribute.userPoolChildAttribute?.id || '')
          }
        >
          {attribute.id}
        </Text>
      </Td>
      <Td fontSize="14px">{attribute.displayNameJa}</Td>
      <Td fontSize="14px">{attribute.displayNameForeign || '-'}</Td>
      {/* ユーザープールIDがuniikeyの場合に追加する */}
      {/* <Td fontSize="14px">
          {attribute.isUseRegistMember ?
            <CheckStatus />
            : '-'
          }
        </Td>
        <Td fontSize="14px">
          {attribute.isRequiredRegistMember ?
            <CheckStatus />
            : '-'
          }
        </Td> */}
      <Td fontSize="14px">{attribute.fieldName}</Td>
      <Td fontSize="14px">{getUiTypeNameByValue(attribute.uiType)}</Td>
      <Td fontSize="14px">{getDataTypeNameByValue(attribute.dataType)}</Td>
      <Td fontSize="14px">
        {codeGroupName(
          typeof attribute.codeGroup === 'object'
            ? attribute?.codeGroup?.id
            : attribute.codeGroup,
        ) || '-'}
      </Td>
      <Td fontSize="14px">
        <Status
          status={attribute.isPersonalInformation}
          onLabel="ON"
          offLabel="OFF"
        />
      </Td>
      <TdWithBorder fontSize="14px">{attribute.jsonPath || '-'}</TdWithBorder>
      <Td fontSize="14px">
        <Flex gridGap={2}>
          <Status
            status={!!attribute.userPoolChildAttribute?.id}
            onLabel="ON"
            offLabel="OFF"
          />
          <Button
            variant="outline"
            colorScheme="primary"
            bgColor="WHITE"
            w="32px"
            h="32px"
            p="0"
            disabled={!!attribute.userPoolChildAttribute?.id}
            onClick={() =>
              onOpenChildAttrDialog(
                attribute.id,
                attribute.userPoolChildAttribute?.id || '',
              )
            }
          >
            <EditIcon />
          </Button>
        </Flex>
      </Td>
      <TdWithBorder fontSize="14px">
        <Flex gridGap={2}>
          <Status
            status={!!attribute.userPoolChildAttribute?.isFormAttr}
            onLabel="ON"
            offLabel="OFF"
          />
          <Button
            variant="outline"
            colorScheme="primary"
            bgColor="WHITE"
            w="32px"
            h="32px"
            p="0"
            disabled={!!attribute.userPoolChildAttribute?.isFormAttr}
            onClick={() =>
              onOpenFormAttrDialog(
                attribute.id,
                attribute.userPoolChildAttribute?.id || '',
              )
            }
          >
            <EditIcon />
          </Button>
        </Flex>
      </TdWithBorder>
    </Tr>
  ),
);
