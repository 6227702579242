import { getLogs } from 'admin/api/userPool/rule/getLogs';
import { CustomError } from 'admin/error/CustomError';
import { RuleType } from 'admin/types/userPool/rule';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useLogs = (id: RuleType['id']) => {
  const { data } = useQuery({
    queryKey: queryKeys.getLogs(id),
    queryFn: () => getLogs(id),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
