import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { rulesSchema } from 'admin/schema/idPoolConsumer/rule';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { RulesType } from 'admin/types/userPool/rule/index';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { DataWithPage, Page } from 'api/common/types';
import { ZodError } from 'zod';

const dummyPage: Page = {
  count: 123,
  currentPage: 1,
  countFrom: 11,
  countTo: 20,
  perPage: 10,
  pageCount: 30,
  next: 'http://api.example.org/accounts/?page=4',
  previous: 'http://api.example.org/accounts/?page=2',
};

export const dummyData: RulesType = [
  {
    fieldName: 'dummyFieldName1',
    displayName: 'dummyDisplayName1',
    displayEnName: 'displayEnName1',
    id: '001',
    version: 1,
    url: 'https://dummy.com',
    agreeRequiredVesion: 1,
    status: false,
    commonUse: false,
  },
  {
    fieldName: 'dummyFieldName2',
    displayName: 'dummyDisplayName2',
    displayEnName: 'displayEnName2',
    id: '002',
    version: 1,
    url: 'https://dummy.com',
    agreeRequiredVesion: null,
    status: true,
    commonUse: false,
  },
  {
    fieldName: 'dummyFieldName3',
    displayName: 'dummyDisplayName3',
    displayEnName: 'displayEnName3',
    id: '003',
    version: 2,
    url: 'https://dummy.com',
    agreeRequiredVesion: 1,
    status: true,
    commonUse: false,
  },
];

export const getRules = async (
  id: string,
  currentPage: number,
): Promise<DataWithPage<RulesType>> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const path = userId ? `user/${userId}/` : 'user/';
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'get',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  console.log(id, currentPage);

  const response = await getDummySuccessResponse<DataWithPage<RulesType>>({
    results: dummyData,
    ...dummyPage,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_RULES.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_RULES.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    rulesSchema.parse(response.data.results);
    pageSchema.parse(convertToPage<DataWithPage<RulesType>>(response.data));
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
