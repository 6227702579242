import { ServiceType } from 'admin/types/service';
import { Page } from 'api/common/types';

export const queryServiceKey = {
  getServices: (corporationId: string) => ['services', corporationId],
  getService: (id: ServiceType['id']) => ['services', id],
  getServiceMembers: (
    serviceId: ServiceType['id'],
    currentPage: Page['currentPage']
  ) => [
    'memberSerivces',
    serviceId,
    currentPage
  ],
} as const;
