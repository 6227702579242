import { VStack } from '@chakra-ui/react';
import { MailSettingCommon } from 'components/paywall/pages/MailSetting/MailSettingCommon';
import { MailSettingPersonal } from 'components/paywall/pages/MailSetting/MailSettingPersonal';
import { useMailSettingCommon } from 'hooks/paywall/useMailSettingCommon';
import { FC, memo } from 'react';

export const MailSettingContents: FC = memo(() => {
  const { commonData, personalDataList } = useMailSettingCommon();

  return (
    <VStack mt="32px" mb="45px" spacing="32px" alignItems="flex-start">
      <MailSettingCommon commonData={commonData} />
      <MailSettingPersonal personalDataList={personalDataList}/>
    </VStack>
  )
});
