import { getService } from 'admin/api/service/getService';
import { CustomError } from 'admin/error/CustomError';
import { ServiceType } from 'admin/types/service';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { successToast } from 'utils/toast';
import { queryServiceKey } from './queryServiceKey';

export const useService = (id: ServiceType['id']) => {
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryServiceKey.getService(id),
    queryFn: () => getService(id),
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'テナント詳細情報を取得しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
