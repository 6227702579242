import { Page } from 'api/common/types';
import { getPromotionList } from 'api/paywall/getPromotionList';
import { useSearch } from 'components/opt/hooks/useSearch';
import {
  Promotion, PromotionListType
} from 'components/paywall/pages/PromotionList/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * プロモーション一覧取得
 * @return {Promise<{page: Page, promotionList: Promotion[]}>} プロモーション一覧
 */
export const usePromotionList = ({
  courseId,
}: {
  courseId: string;
}): {
  page: Page;
  promotionList: Promotion[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();

  const response = useQuery(
    queryPaywallKey.getPromotionList({
      tenantId,
      courseId,
      param
    }),
    () => getPromotionList({
      tenantId,
      courseId,
      param
    }),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const result = response.data as PromotionListType;
  const page: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page,
    promotionList: results,
  };
};
