import {
  Box,
  Button,
  Checkbox,
  Divider,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { ColorSet } from 'admin/define/colors';
import {
  PERMISSION_FEATURES,
  PERMISSION_FEATURE_ADMIN_KEYS,
  PERMISSION_FEATURE_ADMIN_SAML,
  PERMISSION_FEATURE_ADMIN_SERVICE,
  PERMISSION_FEATURE_ADMIN_USER,
  PERMISSION_FEATURE_ADMIN_USER_POOL,
} from 'admin/define/permissions';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { PermissionFeatureKeyType } from 'admin/types/permission';
import { UserPoolType, UserType } from 'admin/types/user';
import { UserEditFormValues } from 'admin/types/user/form';
import { CorporationUserPoolPermissionType } from 'api/user/types';
import {
  CORPORATION_USER_POOL_PERMISSION_TENANT,
  CORPORATION_USER_POOL_PERMISSION_USER_POOL,
} from 'define';
import { FC, memo, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

export type Props = {
  formId: string;
  user: UserType;
  userPermissionList: PermissionFeatureKeyType[];
  userPoolPermissionList: UserPoolType[];
  onChangePermissionCheck: (permission: PermissionFeatureKeyType) => void;
  onChangeUserPoolPermissionCheck: (
    index: number,
    userPoolKey: CorporationUserPoolPermissionType,
  ) => void;
  onClose: () => void;
  onSubmit: (data: UserEditFormValues) => void;
};

export const UsersEditFormComponent: FC<Props> = memo(
  ({
    formId,
    user,
    userPermissionList,
    userPoolPermissionList,
    onChangePermissionCheck,
    onChangeUserPoolPermissionCheck,
    onClose,
    onSubmit,
  }) => {
    const {
      handleSubmit,
      formState: { isSubmitting },
    } = useFormContext<UserEditFormValues>();
    const { errors } = useFormState<UserEditFormValues>();
    const { userPoolIdWithSaml } = useUserCorporationInfo();
    // 権限設定が可能なパーミッションリストの生成
    const permissionSettingList = useMemo(() => {
      const permissionList = [
        PERMISSION_FEATURE_ADMIN_USER,
        PERMISSION_FEATURE_ADMIN_SERVICE,
        PERMISSION_FEATURE_ADMIN_USER_POOL,
      ];

      // SAML設定権限が存在する場合のみ設定可能
      if (userPoolIdWithSaml !== '') {
        permissionList.push(PERMISSION_FEATURE_ADMIN_SAML);
      }

      return permissionList;
    }, [userPoolIdWithSaml]);

    return (
      <>
        {isSubmitting && <LoadingLayer />}
        <DrawerBody>
          <Stack mt="16px" mb="24px" spacing="24px">
            <Stack spacing="8px">
              <Text fontWeight="700">ユーザー名</Text>
              <Text>{user.name}</Text>
            </Stack>
            <Stack spacing="8px">
              <Text fontWeight="700">メールアドレス</Text>
              <Text>{user.email}</Text>
            </Stack>
          </Stack>
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Box mt="24px">
              <Text fontSize="18px" fontWeight="700">
                権限
              </Text>
              <VStack mt="16px" alignItems="flex-start">
                <FormControl
                  isInvalid={Boolean(errors.permission)}
                  display="grid"
                  gridGap="8px"
                >
                  <Text fontWeight="700">一般</Text>
                  {PERMISSION_FEATURE_ADMIN_KEYS.filter((x) =>
                    permissionSettingList.includes(x),
                  ).map((permission, idx) => (
                    <Box
                      key={`permission_feature_${permission}_${String(idx)}`}
                    >
                      <Checkbox
                        name={`permission_feature_${permission}`}
                        isChecked={userPermissionList.includes(permission)}
                        onChange={() => onChangePermissionCheck(permission)}
                      >
                        {PERMISSION_FEATURES.get(permission)}
                      </Checkbox>
                    </Box>
                  ))}
                  {toErrMsgList(errors, 'permission').map((err) => (
                    <FormErrorMessage key={`permission${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errors.userPools)}
                  display="grid"
                  gridGap="8px"
                >
                  {userPoolPermissionList.map((userPool, idx) => (
                    <VStack
                      spacing={2}
                      mt="16px"
                      alignItems="flex-start"
                      key={`user_userPool_${String(idx)}`}
                    >
                      <Text fontWeight="700">{userPool.userPoolName}</Text>
                      <Checkbox
                        name={`userPool_permission_${userPool.userPoolId}_${CORPORATION_USER_POOL_PERMISSION_USER_POOL}`}
                        isChecked={userPool.permission.includes(
                          CORPORATION_USER_POOL_PERMISSION_USER_POOL,
                        )}
                        onChange={() =>
                          onChangeUserPoolPermissionCheck(
                            idx,
                            CORPORATION_USER_POOL_PERMISSION_USER_POOL,
                          )
                        }
                      >
                        IDシステム設定管理
                      </Checkbox>
                      <Checkbox
                        name={`userPool_permission_${userPool.userPoolId}_${CORPORATION_USER_POOL_PERMISSION_TENANT}`}
                        isChecked={userPool.permission.includes(
                          CORPORATION_USER_POOL_PERMISSION_TENANT,
                        )}
                        onChange={() =>
                          onChangeUserPoolPermissionCheck(
                            idx,
                            CORPORATION_USER_POOL_PERMISSION_TENANT,
                          )
                        }
                      >
                        テナント設定管理
                      </Checkbox>
                    </VStack>
                  ))}
                  {toErrMsgList(errors, 'userPools').map((err) => (
                    <FormErrorMessage key={`userPools${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </VStack>
            </Box>
          </form>
        </DrawerBody>
        <DrawerFooter display="flex" justifyContent="end" gridGap={4}>
          <Button
            variant="outline"
            lineHeight={5}
            p="8px 24px"
            h="auto"
            onClick={() => onClose()}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            colorScheme="primary"
            lineHeight={5}
            p="8px 24px"
            h="auto"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
