import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  status: boolean;
};

export const StatusChangeModal: FC<Props> = memo(
  ({ onClose, onSubmit, status }) => (
    <>
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100}>
        <ModalHeader fontSize="lg" fontWeight="bold">
          ステータスの変更
        </ModalHeader>
        <ModalBody>
          <Text>
            ステータスを{status ? '有効' : '無効'}に切り替えます。
            影響範囲を十分に確認した上で実行してください。
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="solid" {...ColorSet.Default} onClick={onClose}>
              キャンセル
            </Button>
            <Button colorScheme="primary" mr={3} onClick={() => onSubmit()}>
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </>
  ),
);
