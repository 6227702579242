import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { logsSchema } from 'admin/schema/idPoolConsumer/rule';
import { LogsType } from 'admin/types/userPool/rule/index';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const getLogs = async (id: string): Promise<LogsType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const path = userId ? `user/${userId}/` : 'user/';
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'get',
  //   options,
  // });
  console.log(id);

  const response = await getDummySuccessResponse<LogsType>([
    {
      id: '001',
      version: 1,
      updateContent: 'updateContent -2',
      memo: 'memo',
      updatedTiming: '2023/12/10 23:44',
      updaterName: 'dummy name1',
    },
    {
      id: '002',
      version: 1,
      updateContent: 'updateContent - 2',
      memo: null,
      updatedTiming: '2023/12/10 23:44',
      updaterName: 'dummy name1',
    },
    {
      id: '003',
      version: 2,
      updateContent: 'updateContent - 2',
      updatedTiming: '2023/12/10 23:44',
      updaterName: 'dummy name1',
    },
  ]);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_LOGS.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_LOGS.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    logsSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
