import { Box, Heading, Modal, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CommonUseModal } from 'admin/components/idPoolConsumer/rule/commonUseModal';
import { RuleEditDrawer } from 'admin/components/idPoolConsumer/rule/ruleDrawer/index';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useChangeRuleStatus } from 'admin/hooks/userPool/rule/useChangeRuleStatus';
import { useRules } from 'admin/hooks/userPool/rule/useRules';
import { RuleType } from 'admin/types/userPool/rule';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AgreeModal } from '../agreeModal';
import { RuleTableComponent } from './RuleTableComponent';

type Props = {
  currentPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchRuleTable: FC<Props> = memo(({ currentPage, setPage }) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure();
  const {
    isOpen: isAgreeModalOpen,
    onClose: onAgreeModalClose,
    onOpen: onAgreeModalOpen,
  } = useDisclosure();
  const {
    isOpen: isCommonUseOpen,
    onClose: onCommonUseModalClose,
    onOpen: onCommonUseModalOpen,
  } = useDisclosure();
  const { mutate, isLoading: isLoadingChangeStatus } = useChangeRuleStatus(
    idPoolConsumerId,
    currentPage,
  );

  const [ruleId, setRuleId] = useState<string>('');
  const [selectedRule, setSelectedRule] = useState<RuleType | null>(null);

  const formId = `ruleEditForm${ruleId}`;

  const {
    data: rules,
    page,
    isLoading: isLoadingRules,
  } = useRules(idPoolConsumerId, currentPage);

  const isLoading = useMemo(
    () => isLoadingRules || isLoadingChangeStatus,
    [isLoadingRules, isLoadingChangeStatus],
  );

  const agreeModalOpen = useCallback(
    (rule: RuleType) => {
      onAgreeModalOpen();
      setSelectedRule(rule);
    },
    [onAgreeModalOpen],
  );

  const commonUseModalOpen = useCallback(
    (rule: RuleType) => {
      onCommonUseModalOpen();
      setSelectedRule(rule);
    },
    [onCommonUseModalOpen],
  );

  useEffect(() => {
    setPage(page);
  }, [setPage, page]);

  if (!rules) {
    return null;
  }

  if (rules.length === 0) {
    return (
      <Box
        w="100%"
        textAlign="center"
        bg="white"
        mt="16px"
        borderRadius={4}
        padding={10}
      >
        <Heading as="h3" fontWeight="bold" fontSize="24px" mb="6">
          組織レベルの規約が未作成です。
        </Heading>
        <Box>
          組織レベルの規約を作成することで、複数のユーザーを効率的に管理し、アクセス権限などを設定できます。
          <br />
          云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々云々
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isLoading && <LoadingLayer />}
      <RuleTableComponent
        rules={rules}
        idPoolConsumerId={idPoolConsumerId}
        onDrawerOpen={onDrawerOpen}
        onAgreeModalOpen={agreeModalOpen}
        onCommonUseModalOpen={commonUseModalOpen}
        setRuleId={setRuleId}
        onChangeStatus={mutate}
      />
      <RuleEditDrawer
        ruleId={ruleId}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        formId={formId}
      />
      <Modal
        isOpen={isAgreeModalOpen}
        onClose={onAgreeModalClose}
        isCentered
        size="xl"
      >
        <AgreeModal onClose={onAgreeModalClose} rule={selectedRule} />
      </Modal>
      <Modal
        isOpen={isCommonUseOpen}
        onClose={onCommonUseModalClose}
        isCentered
        size="xl"
      >
        <CommonUseModal
          onClose={onCommonUseModalClose}
          rule={selectedRule}
          currentPage={currentPage}
        />
      </Modal>
    </>
  );
});

export const RuleTable = memo(
  withSuspenseAndErrorBoundary(fetchRuleTable, {
    ErrorComponent: <ErrorContents name="組織規約一覧" />,
  }),
);
