import { Box, Button, Tr as ChakraTr } from '@chakra-ui/react';
import { Status } from 'admin/components/ui/status';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { ServiceRulesType } from 'admin/types/service/rule/index';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  rules: ServiceRulesType;
  onDrawerOpen: () => void;
  setRuleId: Dispatch<SetStateAction<string>>;
};

export const RuleTableComponent: FC<Props> = memo(
  ({ rules, onDrawerOpen, setRuleId }: Props) => (
    <Box mt="16px">
      <Table w="100%" maxHeight="calc(100vh - 250px)">
        <Thead>
          <ChakraTr>
            <Th maxWidth="80px" minWidth="80px">
              規約ID
            </Th>
            <Th maxWidth="180px">表示名（日本語）</Th>
            <Th maxWidth="180px">表示名（外国語）</Th>
            <Th maxWidth="180px">フィールド名</Th>
            <Th maxWidth="100px" minWidth="100px">
              バージョン
            </Th>
            <Th maxWidth="120px" minWidth="120px">
              URL
            </Th>
            <Th maxWidth="100px" minWidth="100px">
              規約同意
              <br />
              必須バージョン
            </Th>
            <Th>並び順</Th>
            <Th maxWidth="100px" minWidth="100px">
              ステータス
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {rules.map((rule) => (
            <Tr key={`serviceRule${rule.id}${rule.name}`}>
              <Td maxWidth="100px">
                {' '}
                <Button
                  fontWeight="normal"
                  textDecoration="underline"
                  variant="link"
                  colorScheme="primary"
                  whiteSpace="break-spaces"
                  textAlign="left"
                  onClick={() => {
                    setRuleId(rule.id);
                    onDrawerOpen();
                  }}
                >
                  {rule.id}
                </Button>
              </Td>
              <Td maxWidth="180px" minWidth="180px">
                {rule.displayName}
              </Td>
              <Td maxWidth="180px" minWidth="180px">
                {rule.displayEnName}
              </Td>
              <Td maxWidth="180px" minWidth="180px">
                {rule.fieldName}
              </Td>
              <Td maxWidth="100px" minWidth="100px">
                {rule.version}
              </Td>
              <Td maxWidth="200px" minWidth="200px">
                {rule.url}
              </Td>
              <Td maxWidth="100px" minWidth="100px">
                {rule.agreeRequiredVesion ?? '-'}
              </Td>
              <Td maxWidth="100px" minWidth="100px">
                {rule.sortNumber}
              </Td>
              <Td>
                <Status status={rule.status} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
