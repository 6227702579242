import { customErrorMessage, toZod } from 'admin/config/zod';
import { ServiceMemberType, ServiceType } from 'admin/types/service';
import { ServiceAllocateUserPoolResponseType, ServiceFormType } from 'admin/types/service/form';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const serviceSchema = z.object<toZod<ServiceType>>({
  id: z.string(),
  name: z.string(),
  userPoolChildId: z.string().nullable(),
  userPoolId: z.string().nullable(),
  userPoolName: z.string().nullable(),
});

export const servicesSchema = z.array(serviceSchema);

export const serviceFormSchema = z.object<toZod<ServiceFormType>>({
  id: z.string(),
  userPoolId: z.string().min(1, '使用するユーザープールは必須です'),
  word: z
    .string()
    .min(1, '"assign"と入力してください')
    .refine((val) => val === 'assign', {
      message: '"assign"と入力してください',
    }),
});

export const serviceAllocateUserPoolResponseSchema = z.object<toZod<ServiceAllocateUserPoolResponseType>>({
  userPoolChildId: z.string(),
  corporationUserPoolId: z.number(),
})

// 会員テナント
export const serviceMemberSchema = z.object<toZod<ServiceMemberType>>({
  id: z.string(),
  serviceId: serviceSchema.shape.id,
  email: z.string(),
  tel: z.string(),
  registeredDate: z.string(),
  lastLoginDate: z.string(),
  status: z.boolean(),
});

export const serviceMembersSchema = z.array(serviceMemberSchema);
