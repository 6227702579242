import { AttributeGroupTableBodyComponent } from 'admin/components/service/attribute/attributeGroupTableBody/AttributeGroupTableBodyComponent';
import {
  ServiceAttributeGroupType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attributeGroup: ServiceAttributeGroupType;
  onOpen: (
    id: ServiceAttributeGroupType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenFormAttrDialog: (
    id: ServiceAttributeGroupType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
  onOpenChildAttrDialog: (
    id: ServiceAttributeGroupType['id'],
    userPoolChildAttributeId: UserPoolChildAttributeType['id'],
  ) => void;
};

export const AttributeGroupTableBody: FC<Props> = memo((props: Props) => (
  <AttributeGroupTableBodyComponent {...props} />
));
