/* eslint-disable prefer-object-spread */
import { EditIcon } from '@chakra-ui/icons';
import { Box, Stack, Text } from '@chakra-ui/react';
import { ContentsDbTblFormType } from 'api/contents/types';
import ErrorBoundary from 'api/ErrorBoundary';
import { AlertBar, ErrorStatusContents } from 'components/common/atoms';
import { GroupSelect } from 'components/contentsdb/atoms/GroupSelect';
import { InputTableName } from 'components/contentsdb/atoms/InputTableName';
import { InputTableTitle } from 'components/contentsdb/atoms/InputTableTitle';
import { SegmentConnectInfo } from 'components/contentsdb/atoms/SegmentConnectInfo';
import { TextTableMemo } from 'components/contentsdb/atoms/TextTableMemo';
import { SchemaCreateForm } from 'components/contentsdb/molecules/SchemaCreateForm';
import {
  TableForm,
  TableFormChildProps
} from 'components/contentsdb/molecules/TableForm';
import { useTableData } from 'hooks/contentsdb/useTableData';
import { useTableId } from 'hooks/contentsdb/useTableId';
import { useTableRegist } from 'hooks/contentsdb/useTableRegist';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Suspense, useCallback, useEffect, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';

const OpenBtnChild: VFC = () => (
  <>
    <EditIcon mr={2} fontSize="xs" fontWeight="thin" />
    <Text as="span">編集</Text>
  </>
);

const TableFormChild: VFC<TableFormChildProps> = ({
  formId,
  tableId = '',
  closeHandler,
}) => {
  const [globalError, setGlobalError] = useState<string[]>([]);
  const { handleSubmit, setValue } = useFormContext<ContentsDbTblFormType>();
  const tenantId = useUserTenantId();
  const contentsTable = useTableData({ tenantId, tblId: tableId });
  const queryClient = useQueryClient();

  const regist = useTableRegist({
    closeHandler,
    setGlobalError,
    tenantId,
    tableId,
  });

  useEffect(() => {
    setValue('name', contentsTable?.name as string);
    setValue('title', contentsTable?.title as string);
  }, [contentsTable, setValue]);

  const onSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    handleSubmit((data: ContentsDbTblFormType) => {
      const editTblSchemaIdxs = Object.keys(data.tblSchema).map((idx) =>
        Number(idx),
      );
      // 参照を切って変数に一度格納する
      const changeTblData = Object.assign({}, data);
      const changeTblSchemaData = Object.assign({}, changeTblData.tblSchema);
      // 一度`changeTblData`のtblSchemaは空配列に初期化する
      changeTblData.tblSchema = {};

      // テーブル編集時 data.tblSchema は変更した行しか入ってないので
      // 変更してない行も入れておく
      // (主キー以外だけで更新できなくなったり、変更してない行が消失するのを防ぐ為)
      contentsTable?.tblSchema.forEach((row, i) => {
        if (editTblSchemaIdxs.includes(i + 1)) {
          /**
           * 更新データのdisplayName以外のフィールドが初期化されるケースがあるため、
           * displayName以外は元データをセットする
           * ※編集時はdisplayNameしか編集ができないため
           */
          changeTblData.tblSchema[i + 1] = {
            ...row,
            displayName: changeTblSchemaData[i + 1].displayName,
          };
        } else {
          /**
           * 変更したレコードはdataに格納されている。
           * そのため、変更していないレコードをdataに格納してsubmit処理を実行する
           */
          // eslint-disable-next-line
          changeTblData.tblSchema[i + 1] = row;
        }
      });

      return regist(changeTblData, queryClient);
    }),
    [contentsTable?.tblSchema, handleSubmit, queryClient, regist],
  );

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Stack spacing="24px">
        {globalError ? (
          <Box>
            <AlertBar status="error" message={globalError} />
          </Box>
        ) : null}
        <InputTableName readonly />
        <InputTableTitle />
        <GroupSelect value={contentsTable?.group || ''} />
        <TextTableMemo value={contentsTable?.memo || ''} />
        <SegmentConnectInfo />
        <SchemaCreateForm value={contentsTable?.tblSchema} readonly />
      </Stack>
    </form>
  );
};

const TableEditFormInner: VFC = () => {
  const tblId = useTableId();
  const tenantId = useUserTenantId();
  const contentsTable = useTableData({ tenantId, tblId });

  if (!contentsTable) {
    return <></>;
  }

  return (
    <TableForm
      title="テーブル編集"
      tableId={tblId}
      TableFormChild={TableFormChild}
      OpenBtnChild={OpenBtnChild}
    />
  );
};

export const TableEditForm: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<></>}>
        <Box m={4}>
          <TableEditFormInner />
        </Box>
      </Suspense>
    </ErrorBoundary>
  );
};
