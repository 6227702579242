import { Card, CardHeader } from 'components/paywall/features/Card';
import { PromotionCardHeader } from 'components/paywall/pages/PromotionList/PromotionCardHeader';
import { PromotionCardItems } from 'components/paywall/pages/PromotionList/PromotionCardItems';
import { Promotion } from 'components/paywall/pages/PromotionList/typed';
import { FC, memo } from 'react';

type Props = {
  promotion: Promotion;
}

export const PromotionCard: FC<Props> = memo(({promotion}: Props) => (
  <Card>
    <CardHeader padding="8px 24px 0px" bg="transparent">
      <PromotionCardHeader promotion={promotion}/>
    </CardHeader>
    <PromotionCardItems promotion={promotion}/>
  </Card>
));
