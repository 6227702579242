import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  attributeGroupBodySchema,
  attributeGroupSchema,
} from 'admin/schema/idPoolConsumer/attributeGroup';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import {
  ServiceAttributeFormType,
  ServiceAttributeGroupFormType,
} from 'admin/types/service/attribute/form';
import { Code, CodeGroup } from 'api/enquete/types';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const userPoolChildAttributeType = z.object<
  toZod<UserPoolChildAttributeType>
>({
  id: z.string(),
  userAttributeSetting: z.string(),
  attributeGroupHead: z.string().nullable(),
  userPoolChild: z.string(),
  isFormAttr: z.boolean(),
  isUserAttr: z.boolean(),
  isUserAttrRequired: z.boolean(),
});

const codeScema = z.object<toZod<Code>>({
  id: z.string(),
  code: z.string(),
  displayNameJa: z.string(),
  displayNameForeign: z.string(),
  displayOrder: z.number(),
  codeGroup: z.string(),
});

const codesScema = z.array(codeScema);

const codeGroupSchema = z
  .object<toZod<CodeGroup>>({
    id: z.string(),
    displayNameJa: z.string(),
    displayNameForeign: z.string(),
    displayOrder: z.number(),
    userPool: z.string(),
    codes: codesScema,
  })
  .or(z.string())
  .nullable();

export const serviceAttributeSchema = z.object<toZod<ServiceAttributeType>>({
  id: z.string(),
  userPool: z.string(),
  codeGroup: codeGroupSchema,
  displayNameForeign: z.string().nullable(),
  displayNameJa: z.string(),
  fieldName: z.string().nullable(),
  dataType: z.string(),
  uiType: z.string(),
  isPersonalInformation: z.boolean(),
  jsonPath: z.string().nullable(),
  status: z.boolean(),
  isAutoAppendToChild: z.boolean(),
  userPoolChildAttribute: userPoolChildAttributeType.nullable(),
});

export const serviceAttributesSchema = z.array(serviceAttributeSchema);

export const serviceAttributeChildSchema = userPoolChildAttributeType
  .omit({
    userAttributeSetting: true,
  })
  .extend({
    userAttributeSetting: serviceAttributeSchema.omit({
      status: true,
      userPoolChildAttribute: true,
    }),
  });

export const serviceAttributeFormSchema = z.object<
  toZod<ServiceAttributeFormType>
>({
  userAttributeSetting: z.string(),
  attributeGroupHead: z.string().nullable(),
  isUserAttr: z.boolean().optional(),
  isUserAttrRequired: z.boolean().optional(),
  isFormAttr: z.boolean().optional(),
});

export const userAttributeSettingSchema = serviceAttributeSchema.omit({
  status: true,
  codeGroup: true,
  userPoolChildAttribute: true,
});

export const serviceAttributeGroupSchema = attributeGroupSchema.extend({
  attributeGroupBodyList: z.array(attributeGroupBodySchema),
});

export const serviceAttributeGroupChildSchema = userPoolChildAttributeType
  .omit({
    attributeGroupHead: true,
    userAttributeSetting: true,
  })
  .extend({
    userAttributeSetting: z.string().nullable(),
    attributeGroupHead: serviceAttributeSchema.omit({
      codeGroup: true,
      fieldName: true,
      dataType: true,
      uiType: true,
      isPersonalInformation: true,
      jsonPath: true,
    }),
  });

export const serviceAttributeGroupsSchema = z.array(
  serviceAttributeGroupSchema,
);

export const serviceAttributeGroupFormSchema = z.object<
  toZod<ServiceAttributeGroupFormType>
>({
  userAttributeSetting: z.string().nullable(),
  attributeGroupHead: z.string(),
  isUserAttr: z.boolean().optional(),
  isUserAttrRequired: z.boolean().optional(),
  isFormAttr: z.boolean().optional(),
});
