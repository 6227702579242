import {
  Thead as ChakraThead
} from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode
}

export const Thead: FC<Props> = memo(({children}: Props) => (
  <ChakraThead
    px="16px"
    position="sticky"
    zIndex="sticky"
    top={0}
    bgColor={Colors.GRAY_70}
  >
    {children}
  </ChakraThead>

))
