import { Box, Button, Switch, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { RulesType, RuleType } from 'admin/types/userPool/rule';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  rules: RulesType;
  idPoolConsumerId: string;
  onDrawerOpen: () => void;
  onAgreeModalOpen: (rule: RuleType) => void;
  onCommonUseModalOpen: (rule: RuleType) => void;
  setRuleId: Dispatch<SetStateAction<string>>;
  onChangeStatus: (vale: { id: string; status: boolean }) => void;
};

export const RuleTableComponent: FC<Props> = memo(
  ({
    rules,
    idPoolConsumerId,
    onDrawerOpen,
    setRuleId,
    onAgreeModalOpen,
    onCommonUseModalOpen,
    onChangeStatus,
  }: Props) => {
    const { navigate } = useCustomNavigate({});

    return (
      <Box mt="16px">
        <Table w="100%" maxHeight="calc(100vh - 250px)">
          <Thead>
            <ChakraTr>
              <Th maxWidth="130px" minWidth="130px">
                規約ID
              </Th>
              <Th maxWidth="180px" minWidth="180px">
                表示名（日本語）
              </Th>
              <Th maxWidth="180px" minWidth="180px">
                表示名（外国語）
              </Th>
              <Th maxWidth="120px" minWidth="120px">
                フィールド名
              </Th>
              <Th maxWidth="110px" minWidth="110px">
                バージョン
              </Th>
              <Th maxWidth="240px" minWidth="240px">
                URL
              </Th>
              <Th maxWidth="120px" minWidth="120px">
                規約同意
                <br />
                必須バージョン
              </Th>
              <Th maxWidth="180px" minWidth="180px">
                同意の必須
              </Th>
              <Th maxWidth="180px" minWidth="180px">
                共通利用
              </Th>
              <Th maxWidth="100px" minWidth="100px">
                更新履歴
              </Th>
              <Th maxWidth="110px" minWidth="110px">
                ステータス
              </Th>
            </ChakraTr>
          </Thead>
          <Tbody>
            {rules.map((rule) => (
              <Tr key={`rule${rule.id}${rule?.displayName ?? ''}`}>
                <Td>
                  <Button
                    textDecoration="underline"
                    variant="link"
                    colorScheme="primary"
                    whiteSpace="break-spaces"
                    textAlign="left"
                    onClick={() => {
                      setRuleId(rule.id);
                      onDrawerOpen();
                    }}
                  >
                    {rule.id}
                  </Button>
                </Td>
                <Td>{rule.displayName}</Td>
                <Td>{rule.displayEnName}</Td>
                <Td>{rule.fieldName}</Td>

                <Td>{rule.version}</Td>
                <Td>{rule.url}</Td>
                <Td>{rule.agreeRequiredVesion ?? '-'}</Td>
                <Td py={2}>
                  <Button
                    height="auto"
                    variant="outline"
                    colorScheme="primary"
                    isDisabled={rule?.agreeRequiredVesion === rule.version}
                    onClick={() => onAgreeModalOpen(rule)}
                    py={1}
                    textAlign="left"
                  >
                    現バージョンへの
                    <br />
                    同意を必須にする
                  </Button>
                </Td>
                <Td>
                  <Button
                    height="auto"
                    variant="outline"
                    colorScheme="primary"
                    isDisabled={rule?.commonUse}
                    onClick={() => onCommonUseModalOpen(rule)}
                    py={1}
                    textAlign="left"
                  >
                    すべてのサービス
                    <br />
                    共通で利用する
                  </Button>
                </Td>
                <Td>
                  <Button
                    fontWeight="normal"
                    textDecoration="underline"
                    variant="link"
                    colorScheme="primary"
                    py="4px"
                    onClick={() =>
                      navigate(
                        `/admin/idPoolConsumer/${idPoolConsumerId}/rule/${rule.id}/log`,
                      )
                    }
                  >
                    更新履歴
                  </Button>
                </Td>
                <Td>
                  <Switch
                    onChange={(e) => {
                      onChangeStatus({
                        id: rule.id,
                        status: e.target.checked,
                      });
                    }}
                    isChecked={rule.status}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  },
);
