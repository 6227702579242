import { ButtonProps } from '@chakra-ui/react';

export const Colors = {
  GRAY_03: '#272E35',
  GRAY_01: '#4A545E',
  GRAY_00: '#555F6D',
  GRAY_10: '#7E8B99',
  GRAY_05: '#727375',
  GRAY_15: '#9EA8B3',
  GRAY_20: '#AAAAAA',
  GRAY_30: '#CFD6DD',
  GRAY_40: '#DEE3E7',
  GRAY_50: '#E9ECEF',
  GRAY_60: '#E3E6EA',
  GRAY_65: '#F0F3F5',
  GRAY_70: '#F5F7F9',
  GRAY_80: '#f5f5f5',
  BLUE_LIGHT_01: '#D7E4FF',
  BLUE_00: '#113997',
  BLUE_10: '#1E50C0',
  BLUE_20: '#3062D4',
  BLUE_30: '#D7E4FF',
  RED: '#FF0000',
  RED_01: '#ed143d',
  RED_00: '#6F2020',
  RED_10: '#952D2D',
  RED_20: '#C53434',
  RED_30: '#FCD9D9',
  RED_40: '#FEF5F5',
  RED_50: '#FFFAFA',
  RED_60: '#EC0000',
  GREEN_00: '#0E4E30',
  GREEN_10: '#1D7C4D',
  GREEN_20: '#C6F1DA',
  ORANGE_00: '#7A4510',
  ORANGE_10: '#F59638',
  ORANGE_20: '#FCDEC0',
  WHITE: '#FFFFFF',
  BLACK: '#000000'
}

export const ColorSet: {[key: string]: ButtonProps} = {
  RED: {
    color: Colors.WHITE,
    bgColor: Colors.RED_20,
    _hover: {
      bgColor: Colors.RED_10,
    },
    _active: {
      bgColor: Colors.RED_00,
    },
  },
  BLUE: {
    color: Colors.WHITE,
    bgColor: Colors.BLUE_20,
    _hover: {
      bgColor: Colors.BLUE_10,
    },
    _active: {
      bgColor: Colors.BLUE_00,
    },
  },
  WHITE: {
    color: Colors.BLACK,
    bgColor: Colors.WHITE,
    border: `1px solid ${Colors.GRAY_30}`,
    _hover: {
      bgColor: Colors.GRAY_70,
    },
    _active: {
      bgColor: Colors.GRAY_50,
    },
  }
}
