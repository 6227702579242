import { Page, isPage } from 'api/common/types';
/**
 * メール一覧 カラム用フィルター Type
 */
export type MailColumnsFilter = {
  id: number | string;
  name: string;
};

/**
 * メール
 */
export type Mail = {
  id: string;
  subject: string;
  editorHtml: string | null;
  bodyHtml: string | null;
  editorText: string | null;
  bodyText: string | null;
  fromAddress: string | null;
  replayAddress: string | null;
  deliveryTo: number;
  deliveryType: number;
  segment: string | null;
  segmentName: string | null;
  reserve: string | null;
  deliveryStart: string | null;
  deliveryEnd: string | null;
  estimateCnt: number;
  deliveryCnt: number;
  deliveryRate: number;
  errHardCnt: number;
  errSoftCnt: number;
  openRate: number;
  openCnt: number;
  status: number;
  statusName: string;
  addressFile: string | null;
  subscription: string | null;
  tenant: string;
  group: string | null;
  uneditableMessate?: string;
};

export const MailDefaultValue: Mail = {
  id: '',
  subject: '',
  editorHtml: '',
  bodyHtml: '',
  editorText: '',
  bodyText: '',
  fromAddress: '',
  replayAddress: '',
  deliveryTo: 0,
  segment: '',
  segmentName: '',
  deliveryType: 0,
  reserve: null,
  deliveryStart: '',
  deliveryEnd: '',
  estimateCnt: 0,
  deliveryCnt: 0,
  deliveryRate: 0,
  errHardCnt: 0,
  errSoftCnt: 0,
  openRate: 0,
  openCnt: 0,
  status: 0,
  statusName: '',
  addressFile: '',
  subscription: '',
  tenant: '',
  group: '',
};

/**
 * メール一覧 カラム Type
 */
export type MailColumns = {
  id: string;
  key: string;
  name: string;
  isSort: boolean;
  isFilter: boolean;
  w: number;
};

/**
 * メール複製
 */
export type MailDuplicate = {
  id: string;
};

/**
 * メール一覧のメール型
 */
export type MailInfo = {
  id: string;
  subject: string;
  reserve: string | null;
  status: number;
  statusName: string;
  segment: string | null;
  segmentName: string | null;
  estimateCnt: number;
  deliveryTo: number;
  deliveryCnt: number;
  deliveryRate: number;
  deliveryStart: string | null;
  deliveryEnd: string | null;
  openCnt: number;
  openRate: number;
  deliveryType: number;
  fromAddress: string | null;
  bodyHtml: string | null;
  bodyText: string | null;
  hasMailLink: boolean;
  linkClickCnt: number;
  linkClickRate: number;
  addressFile?: string | null;
  addressFileOrigin: string;
  isReplaceWord: boolean;
  isReplaceWordMismatch: boolean;
  shortagePlaceholders: string | null;
  excessPlaceholders: string | null;
  mailMagazineId?: string | null;
  mailMagazineTitle?: string | null;
};

/**
 * メール一覧情報 Type
 */
export type MailList = {
  results: MailInfo[];
} & Page;

/**
 * メール 測定結果 Type
 */
export type MeasurementResults = {
  id: number;
  deliveryCnt: number;
  postingOrder: number;
  linkTitle: string | null;
  url: string;
  clickCnt: number;
  clickRate: number;
  mail: string;
};

/**
 * メール設定 Type
 */
export type MailSetting = {
  id: string;
  subject: string;
  fromAddress: string | null;
  replayAddress?: string | null;
  deliveryType: number;
  reserve?: string | null;
  deliveryTo: number;
  segment: string | null;
  addressFile?: string | null;
  addressFileOrigin?: string | null;
  subscription?: string | null;
  uneditableMessate?: string;
  placeholders: string;
  isReplaceWord: boolean;
  isReplaceWordMismatch: boolean;
  shortagePlaceholders: string | null;
  excessPlaceholders: string | null;
  status?: number;
  cancellable?: boolean;
  estimateCnt?: number | null;
};

export const defaultMailSetting = {
  id: '',
  subject: '',
  fromAddress: '',
  replayAddress: '',
  deliveryType: 0,
  reserve: '',
  deliveryTo: 0,
  segment: '',
  addressFile: '',
  subscription: '',
  placeholders: '',
  isReplaceWord: false,
  isReplaceWordMismatch: false,
  shortagePlaceholders: '',
  excessPlaceholders: '',
};

/**
 * メール配信設定 Type
 */
export type MailSettingForm = {
  tenantId: string;
  mailSetting: MailSetting;
};

/**
 * メール作成・編集 Type
 */
export type MailEditorForm = {
  id: string;
  tenant: string;
  subject: string;
  editorHtml: string;
  editorText: string;
  bodyHtml: string;
  bodyText: string;
};
export type MailEditorFormResponse = MailSetting;

/**
 * テストメール送信 Type
 */
export type SendMailForm = {
  id: string;
  tenant: string;
  subject: string;
  bodyHtml: string;
  bodyText: string;
  fromAddress?: string;
  toAddress?: string;
  testMailPlaceholder?: SendMailReplaceType;
};

/**
 * テストメール差し込み語 Type
 */
export type SendMailReplaceType = {
  [key: string]: string;
};

export const SendMailFormDefaultValues: SendMailForm = {
  id: '',
  tenant: '',
  subject: '',
  bodyHtml: '',
  bodyText: '',
  fromAddress: '',
  toAddress: '',
  testMailPlaceholder: {},
};

export const MailEditorFormDefaultValues: MailEditorForm = {
  id: '',
  tenant: '',
  subject: '',
  editorHtml: '{"body":{"rows":[]}}',
  bodyHtml: '',
  editorText: '',
  bodyText: '',
};

export type CreateMailForm = {
  tenant: string;
  subject: string;
  editorHtml: string;
  bodyHtml: string;
  editorText: string;
  bodyText: string;
};

export type EditMailForm = {
  id: string;
  tenant: string;
  subject: string;
  editorHtml: string;
  bodyHtml: string;
  editorText: string;
  bodyText: string;
};

/**
 * メール購読対象 type
 */
export type MailSubscribe = {
  id: string;
  name: string;
  source: 'gigya' | 'mail_magazine';
  targetType: number;
};
/**
 * メール購読者リスト
 */
export type MailSubscribeList = {
  subscriptions: MailSubscribe[];
};

/**
 * メール配信対象件数
 */
export type MailTargetCnt = {
  cnt: number;
};

/**
 * メール配信解除
 */
export type MailCancelReserve = {
  mailId: string;
};

export const isMail = (data: unknown): data is Mail => {
  const m = data as Mail;

  return (
    typeof m?.id === 'string' &&
    typeof m?.subject === 'string' &&
    (m?.editorHtml !== null ? typeof m?.editorHtml === 'string' : true) &&
    (m?.bodyHtml !== null ? typeof m?.bodyHtml === 'string' : true) &&
    (m?.editorText !== null ? typeof m?.editorText === 'string' : true) &&
    (m?.bodyText !== null ? typeof m?.bodyText === 'string' : true) &&
    (m?.fromAddress !== null ? typeof m?.fromAddress === 'string' : true) &&
    (m?.replayAddress !== null ? typeof m?.replayAddress === 'string' : true) &&
    typeof m?.deliveryTo === 'number' &&
    typeof m?.deliveryType === 'number' &&
    (m?.segment !== null && m?.segment !== undefined
      ? typeof m?.segment === 'string'
      : true) &&
    (m?.segmentName !== null ? typeof m?.segmentName === 'string' : true) &&
    (m?.reserve !== null ? typeof m?.reserve === 'string' : true) &&
    (m?.deliveryStart !== null ? typeof m?.deliveryStart === 'string' : true) &&
    (m?.deliveryEnd !== null ? typeof m?.deliveryEnd === 'string' : true) &&
    typeof m?.estimateCnt === 'number' &&
    typeof m?.deliveryCnt === 'number' &&
    typeof m?.deliveryRate === 'number' &&
    typeof m?.errHardCnt === 'number' &&
    typeof m?.errSoftCnt === 'number' &&
    typeof m?.openRate === 'number' &&
    typeof m?.openCnt === 'number' &&
    typeof m?.status === 'number' &&
    typeof m?.statusName === 'string' &&
    (m?.addressFile !== null ? typeof m?.addressFile === 'string' : true) &&
    (m?.subscription !== null ? typeof m?.subscription === 'string' : true) &&
    typeof m?.tenant === 'string' &&
    (m?.group !== null ? typeof m?.group === 'string' : true)
  );
};

export const isMailInfo = (data: unknown): data is MailInfo => {
  const m = data as MailInfo;

  return (
    typeof m?.id === 'string' &&
    typeof m?.subject === 'string' &&
    (m?.reserve !== null ? typeof m?.reserve === 'string' : true) &&
    typeof m?.status === 'number' &&
    typeof m?.statusName === 'string' &&
    (m?.segment !== null && m?.segment !== undefined
      ? typeof m?.segment === 'string'
      : true) &&
    (m?.segmentName !== null ? typeof m?.segmentName === 'string' : true) &&
    typeof m?.estimateCnt === 'number' &&
    typeof m?.deliveryCnt === 'number' &&
    typeof m?.deliveryRate === 'number' &&
    typeof m?.deliveryTo === 'number' &&
    typeof m?.openRate === 'number' &&
    typeof m?.deliveryType === 'number' &&
    (m?.fromAddress !== null ? typeof m?.fromAddress === 'string' : true) &&
    (m?.bodyHtml !== null ? typeof m?.bodyHtml === 'string' : true) &&
    (m?.bodyText !== null ? typeof m?.bodyText === 'string' : true) &&
    typeof m?.hasMailLink === 'boolean' &&
    typeof m?.linkClickCnt === 'number' &&
    typeof m?.linkClickRate === 'number'
  );
};

export const isMailInfoList = (datas: unknown[]): datas is MailInfo[] =>
  !datas.some((d) => !isMailInfo(d));

export const isMailList = (data: unknown): data is MailList => {
  const m = data as MailList;

  return isPage(m) && isMailInfoList(m?.results);
};

export const isMeasurementResults = (
  data: unknown,
): data is MeasurementResults => {
  const m = data as MeasurementResults;

  return (
    typeof m?.id === 'number' &&
    typeof m?.deliveryCnt === 'number' &&
    typeof m?.postingOrder === 'number' &&
    (m?.linkTitle !== null ? typeof m?.linkTitle === 'string' : true) &&
    typeof m?.url === 'string' &&
    typeof m?.clickCnt === 'number' &&
    typeof m?.clickRate === 'number' &&
    typeof m?.mail === 'string'
  );
};

export const isMeasurementResultsList = (
  datas: unknown[],
): datas is MeasurementResults[] =>
  !datas.some((d) => !isMeasurementResults(d));

export const isMailSetting = (data: unknown): data is MailSetting => {
  const ms = data as MailSetting;

  return (
    typeof ms?.id === 'string' &&
    typeof ms?.subject === 'string' &&
    (ms?.fromAddress !== null ? typeof ms?.fromAddress === 'string' : true) &&
    (ms?.replayAddress !== null
      ? typeof ms?.replayAddress === 'string'
      : true) &&
    typeof ms?.deliveryType === 'number' &&
    (ms?.reserve !== null ? typeof ms?.reserve === 'string' : true) &&
    typeof ms?.deliveryTo === 'number' &&
    (ms?.segment !== null && ms?.segment !== undefined
      ? typeof ms?.segment === 'string'
      : true) &&
    (ms?.addressFile !== null ? typeof ms?.addressFile === 'string' : true) &&
    (ms?.addressFileOrigin !== null
      ? typeof ms?.addressFileOrigin === 'string'
      : true) &&
    (ms?.subscription !== null ? typeof ms?.subscription === 'string' : true) &&
    (ms?.uneditableMessate !== undefined
      ? typeof ms?.uneditableMessate === 'string'
      : true) &&
    typeof ms?.isReplaceWord === 'boolean' &&
    typeof ms?.isReplaceWordMismatch === 'boolean' &&
    (ms?.shortagePlaceholders !== null
      ? typeof ms?.shortagePlaceholders === 'string'
      : true) &&
    (ms?.excessPlaceholders !== null
      ? typeof ms?.excessPlaceholders === 'string'
      : true)
  );
};

export const isMailEditorForm = (data: unknown): data is MailEditorForm => {
  const me = data as MailEditorForm;

  return (
    typeof me?.id === 'string' &&
    typeof me?.tenant === 'string' &&
    typeof me?.subject === 'string' &&
    typeof me?.editorHtml === 'string' &&
    typeof me?.bodyHtml === 'string' &&
    typeof me?.editorText === 'string' &&
    typeof me?.bodyText === 'string'
  );
};

export const isMailSubscribe = (data: unknown): data is MailSubscribe => {
  const ms = data as MailSubscribe;

  return (
    typeof ms?.id === 'string' &&
    typeof ms?.name === 'string' &&
    typeof ms?.source === 'string' &&
    (ms?.source === 'gigya' || ms?.source === 'mail_magazine') &&
    typeof ms?.targetType === 'number'
  );
};

export const isMailSubscribeList = (
  data: unknown,
): data is MailSubscribeList => {
  const msl = data as MailSubscribeList;

  return msl.subscriptions.length > 0
    ? msl.subscriptions.some((d) => isMailSubscribe(d))
    : true;
};

export const isMailTargetCnt = (data: unknown): data is MailTargetCnt => {
  const mtc = data as MailTargetCnt;

  return typeof mtc?.cnt === 'number';
};

export const isSendMailForm = (data: unknown): data is SendMailForm => {
  const sm = data as SendMailForm;

  return (
    typeof sm?.subject === 'string' &&
    typeof sm?.bodyHtml === 'string' &&
    typeof sm?.bodyText === 'string' &&
    typeof sm?.fromAddress === 'string' &&
    typeof sm?.toAddress === 'string'
  );
};
