import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import {
  isPromotionList, PromotionListType
} from 'components/paywall/pages/PromotionList/typed';
import { Options } from 'ky';
import { messages } from './messages';
/**
 * プロモーション一覧取得 API
 * @param {string} tenantId テナントID
 * @param {string} courseId コースID
 * @param {Options} options APIオプション
 * @return {Promise<PromotionListType>} プロモーション一覧
 */
export const getPromotionList = async ({
  tenantId,
  courseId,
  param,
  options
}:{
  tenantId: string,
  courseId: string,
  param: string;
  options?: Options,
}): Promise<PromotionListType> => {
  const path = `paywall/${tenantId}/${courseId}/promotion/?${param}`;
  if (!courseId)
    return {
      count: 0,
      countFrom: 0,
      countTo: 0,
      currentPage: 0,
      next: '',
      pageCount: 0,
      perPage: 0,
      previous: '',
      results: [],
    };

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getPromotionList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getPromotionList.customer.error);
    }
  }

  if (!isPromotionList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getPromotionList.system.typeError,
    });

    throw new CustomError(messages.getPromotionList.customer.failed);
  }

  return responseData;
};
