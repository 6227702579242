import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { createRuleSchema } from 'admin/schema/idPoolConsumer/rule';
import { RuleType } from 'admin/types/userPool/rule/';
import { CreateRuleFormType } from 'admin/types/userPool/rule/form';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const getRule = async (id: string): Promise<CreateRuleFormType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const path = userId ? `user/${userId}/` : 'user/';
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'get',
  //   options,
  // });
  console.log(id);

  const response = await getDummySuccessResponse<RuleType>({
    id: '001',
    fieldName: 'dummyFieldName1',
    displayName: 'dummyDisplayName1',
    displayEnName: 'displayEnName1',
    url: 'https://dummy.com',
    version: 1,
    agreeRequiredVesion: 1,
    status: false,
    commonUse: false,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_RULE.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_RULE.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    createRuleSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
