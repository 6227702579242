import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { RuleCreateDrawer } from 'admin/components/idPoolConsumer/rule/ruleDrawer/index';
import { RuleTitleComponent } from 'admin/components/idPoolConsumer/rule/ruleTitle/RuleTitleComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

const PlainRuleTitle: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = 'ruleCreateForm';

  return (
    <>
      <RuleTitleComponent onOpen={onOpen} />
      {/* ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <RuleCreateDrawer onClose={onClose} isOpen={isOpen} formId={formId} />
      </Drawer>
    </>
  );
});

export const RuleTitle = withSuspenseAndErrorBoundary(PlainRuleTitle, {
  ErrorComponent: <ErrorContents name="組織レベル規約設定タイトル" />,
  LoadingComponent: <></>,
});
