import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { DisplayTypeIcon } from 'admin/components/ui/icon/displayTypeIcon';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, TdWithBorder, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { DISPLAY_TYPE_LIST } from 'admin/define/field';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { Dispatch, FC, memo, SetStateAction, useMemo } from 'react';

type Props = {
  attributeGroup: AttributeGroupType;
  onOpen: (id: AttributeGroupType['id']) => void;
  setTargetAttributeGroup: Dispatch<
    SetStateAction<AttributeGroupType | undefined>
  >;
  onOpenStatusDialog: () => void;
  onOpenAutoAppendDialog: () => void;
  onOpenFormAttrDialog: () => void;
};

export const AttributeGroupTableInnerComponent: FC<Props> = memo(
  ({
    attributeGroup,
    onOpen,
    setTargetAttributeGroup,
    onOpenStatusDialog,
    onOpenAutoAppendDialog,
    onOpenFormAttrDialog,
  }: Props) => {
    const displayType = useMemo(
      () =>
        DISPLAY_TYPE_LIST.find(
          (item) => item.value === attributeGroup.displayType,
        ),
      [attributeGroup],
    );

    return (
      <Tr>
        <Td fontSize="14px">
          <Text
            color={Colors.BLUE_20}
            cursor="pointer"
            textDecoration="underline"
            fontWeight="bold"
            onClick={() => onOpen(attributeGroup.id)}
          >
            {attributeGroup.id}
          </Text>
        </Td>
        <Td fontSize="14px">{attributeGroup.displayNameJa}</Td>
        <Td fontSize="14px">{attributeGroup.displayNameForeign || '-'}</Td>
        <Td fontSize="14px" padding={0}>
          {displayType && (
            <Flex alignItems="center" ml={4}>
              <DisplayTypeIcon iconType={displayType.value} />
              <Box ml={1}>{displayType.name}</Box>
            </Flex>
          )}
        </Td>
        <TdWithBorder fontSize="14px">
          {attributeGroup.attributeGroupBodyList.map((item, index) => (
            <Box key={`${item.id}${String(index)}`}>
              属性{index + 1}：
              {`${item.userAttributeSetting.displayNameJa} (${item.userAttributeSetting.id})`}
            </Box>
          ))}
        </TdWithBorder>
        <Td fontSize="14px" pr={0}>
          <Flex gridGap={2} alignItems="center">
            <Status
              status={!!attributeGroup.status}
              onLabel="確定"
              offLabel="下書き"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={attributeGroup.status}
              onClick={() => {
                setTargetAttributeGroup(attributeGroup);
                onOpenStatusDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              status={!!attributeGroup.isAutoAppendToChild}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={
                !attributeGroup.status || !!attributeGroup.isAutoAppendToChild
              }
              onClick={() => {
                setTargetAttributeGroup(attributeGroup);
                onOpenAutoAppendDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <TdWithBorder fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              status={!!attributeGroup.isFormAttr}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!attributeGroup.status || !!attributeGroup.isFormAttr}
              onClick={() => {
                setTargetAttributeGroup(attributeGroup);
                onOpenFormAttrDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </TdWithBorder>
        <Td fontSize="14px">{attributeGroup.displayOrder}</Td>
      </Tr>
    );
  },
);
