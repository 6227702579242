import { getServiceRule } from 'admin/api/service/rule/getServiceRule';
import { CustomError } from 'admin/error/CustomError';
import { ServiceRuleType } from 'admin/types/service/rule';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useServiceRule = (id: ServiceRuleType['id']) => {
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryKeys.getServiceRule(id),
    queryFn: () => getServiceRule(id),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
        if (error.logLevel === 'warning') {
          toast({
            ...errorToast,
            duration: 4000,
            title: error.message,
            position: 'bottom',
            variant: 'solid',
          });
        }
      }
    },
  });

  return { data };
};
