import {
  Box,
  Button, ButtonProps, Drawer,
  DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter,
  DrawerHeader,
  DrawerOverlay, DrawerProps, Flex
} from '@chakra-ui/react';
import { useCallback, useRef, VFC } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';

type DrawerFormProps = {
  title?: string | React.ReactNode;
  subInfo?: React.ReactNode;
  children: React.ReactNode;
  openBtnChildNode: React.ReactNode;
  openBtnProps?: ButtonProps;
  openBtnTestId?: string;
  cancelBtnTitle?: string;
  cancelBtnProps?: ButtonProps;
  cancelBtnHandelr?: () => void;
  cancelBtnChildNode?: React.ReactNode;
  submitBtnTitle?: string;
  submitBtnProps?: ButtonProps;
  submitBtnHandelr?: () => void;
  drawerCloseButtonProps?: ButtonProps;
  onOpen: () => void;
  formId: string;
  drawerFooterJustify?: string;
  isFooter?: boolean;
  firstFocus?: string | undefined;
  hasIconWithTitle?: boolean;
} & DrawerProps;

export const DrawerForm: VFC<DrawerFormProps> = ({
  title = '',
  subInfo,
  children,
  openBtnChildNode,
  openBtnProps = {},
  openBtnTestId = '',
  cancelBtnTitle = '',
  cancelBtnProps = {},
  cancelBtnHandelr = () => undefined,
  cancelBtnChildNode = undefined,
  submitBtnTitle = '',
  submitBtnProps = {},
  submitBtnHandelr = () => undefined,
  drawerCloseButtonProps = {},
  isOpen,
  onOpen,
  onClose,
  formId,
  drawerFooterJustify = 'space-between',
  isFooter = true,
  firstFocus = '',
  hasIconWithTitle = false,
  ...drawerProps
}) => {
  const firstField = useRef<HTMLInputElement>(null);
  const customFirstFocus = useRef<HTMLButtonElement>(null);

  const closeHandler = useCallback(() => {
    if (cancelBtnHandelr) {
      cancelBtnHandelr();
    }
    onClose();
  }, [cancelBtnHandelr, onClose]);

  const clickHandler = useCallback(() => {
    if (submitBtnHandelr) {
      submitBtnHandelr();
    }
  }, [submitBtnHandelr]);

  return (
    <>
      <Button
        type="button"
        {...openBtnProps}
        onClick={onOpen}
        data-testid={openBtnTestId}
      >
        {openBtnChildNode}
      </Button>
      <Drawer
        {...drawerProps}
        isOpen={isOpen}
        initialFocusRef={
          firstFocus === 'button' ? customFirstFocus : firstField
        }
        onClose={closeHandler}
      >
        <DrawerOverlay />
        <DrawerContent>
          {title && (
            <DrawerHeader borderBottomWidth="1px">
              {hasIconWithTitle && (
                <Flex alignItems="center" style={{ color: '#444', gap: 5 }}>
                  <Box style={{ height: 24 }}>
                    {title && (
                      <AiFillInfoCircle style={{ width: 24, height: 24 }} />
                    )}
                  </Box>
                  {title}
                  {subInfo && subInfo}
                </Flex>
              )}
              {!hasIconWithTitle && (
                <>
                  {title}
                  {subInfo && subInfo}
                </>
              )}
            </DrawerHeader>
          )}
          <DrawerBody>{children}</DrawerBody>
          {isFooter && (
            <DrawerFooter
              borderTopWidth="1px"
              justifyContent={drawerFooterJustify}
            >
              {cancelBtnChildNode !== undefined
                ? cancelBtnChildNode
                : cancelBtnTitle && (
                    <Button
                      variant="outline"
                      mr={3}
                      onClick={closeHandler}
                      {...cancelBtnProps}
                    >
                      {cancelBtnTitle}
                    </Button>
                  )}
              <Button
                {...submitBtnProps}
                onClick={clickHandler}
                form={formId}
                type="submit"
                ref={firstFocus === 'button' ? customFirstFocus : null}
              >
                {submitBtnTitle}
              </Button>
            </DrawerFooter>
          )}
          <DrawerCloseButton top="4" {...drawerCloseButtonProps} />
        </DrawerContent>
      </Drawer>
    </>
  );
};
