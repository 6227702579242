import {
  Box,
  Flex,
  ListItem,
  Tr as ChakraTr,
  UnorderedList
} from '@chakra-ui/react';
import { AttributeGroupTableInner } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupTableInner';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import {
  Table,
  Tbody,
  Th,
  Thead,
  ThWithBorder
} from 'admin/components/ui/table';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  attributeGroups: AttributeGroupType[];
  onOpen: (id: AttributeGroupType['id']) => void;
  setTargetAttributeGroup: Dispatch<
    SetStateAction<AttributeGroupType | undefined>
  >;
  onOpenStatusDialog: () => void;
  onOpenAutoAppendDialog: () => void;
  onOpenFormAttrDialog: () => void;
};

export const AttributeGroupTableComponent: FC<Props> = memo(
  ({
    attributeGroups,
    onOpen,
    setTargetAttributeGroup,
    onOpenStatusDialog,
    onOpenAutoAppendDialog,
    onOpenFormAttrDialog,
  }: Props) => (
    <Box mt={4}>
      <Table w="100%" maxHeight="calc(100vh - 320px)">
        <Thead height="53px">
          <ChakraTr>
            <Th maxWidth="140px" minWidth="140px" fontSize="14px">
              属性グループID
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（日本語）
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（外国語）
            </Th>
            <Th maxWidth="161px" minWidth="161px" fontSize="14px">
              画面上の配置
            </Th>
            <ThWithBorder maxWidth="230px" minWidth="230px" fontSize="14px">
              使用する属性
            </ThWithBorder>
            <Th maxWidth="124px" minWidth="124px" fontSize="14px">
              ステータス
            </Th>
            <Th maxWidth="150px" minWidth="150px" fontSize="14px" p="0">
              <Flex justifyContent="center">
                全テナント割当
                <HelpTooltip
                  label={
                    <UnorderedList>
                      <ListItem>
                        「ステータス」が「確定」の時、操作可能です。
                      </ListItem>
                      <ListItem>
                        ONにすると、対象の属性グループが全テナントで利用可能になります。
                      </ListItem>
                      <ListItem>
                        一度ONにした設定はOFFに変更できません。
                      </ListItem>
                    </UnorderedList>
                  }
                />
              </Flex>
            </Th>
            <ThWithBorder
              maxWidth="165px"
              minWidth="165px"
              fontSize="14px"
              p="0"
            >
              <Flex justifyContent="center">
                全フォーム利用
                <HelpTooltip
                  label={
                    <UnorderedList>
                      <ListItem>
                        「ステータス」が「確定」の時、操作可能です。
                      </ListItem>
                      <ListItem>
                        ONにすると、対象の属性グループが全テナントのフォームに表示されます。
                      </ListItem>
                      <ListItem>
                        一度ONにした設定はOFFに変更できません。
                      </ListItem>
                    </UnorderedList>
                  }
                />
              </Flex>
            </ThWithBorder>
            <ThWithBorder maxWidth="80px" minWidth="80px" fontSize="14px">
              並び順
            </ThWithBorder>
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributeGroups.map((attributeGroup, idx) => (
            <AttributeGroupTableInner
              key={attributeGroup.id + String(idx)}
              attributeGroup={attributeGroup}
              onOpen={onOpen}
              setTargetAttributeGroup={setTargetAttributeGroup}
              onOpenStatusDialog={onOpenStatusDialog}
              onOpenAutoAppendDialog={onOpenAutoAppendDialog}
              onOpenFormAttrDialog={onOpenFormAttrDialog}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
