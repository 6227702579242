import { createRule } from 'admin/api/userPool/rule/createRule';
import { CustomError } from 'admin/error/CustomError';
import { CreateRuleFormType } from 'admin/types/userPool/rule/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

export const useCreateRule = () => {
  const toast = useCustomToast();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: CreateRuleFormType) => createRule(data),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '組織レベルの規約の作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: '組織レベルの規約を作成しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
