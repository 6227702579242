import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceRulesSchema } from 'admin/schema/service/rule';
import { ServiceRulesType } from 'admin/types/service/rule/index';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const getServiceRules = async (
  _id: string,
): Promise<ServiceRulesType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const path = userId ? `user/${userId}/` : 'user/';
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'get',
  //   options,
  // });
  const response = await getDummySuccessResponse<ServiceRulesType>([
    {
      name: 'dummyDataName1',
      fieldName: 'dummyFieldName1',
      displayEnName: 'dummyDisplayEnName1',
      displayName: 'dummyDisplayName1',
      id: '001',
      version: 1,
      url: 'https://dummy.com',
      agreeRequiredVesion: 1,
      status: false,
      sortNumber: 1,
    },
    {
      name: 'dummyDataName2',
      fieldName: 'dummyFieldName2',
      displayEnName: 'dummyDisplayEnName2',
      displayName: 'dummyDisplayName2',
      id: '002',
      version: 1,
      url: 'https://dummy.com',
      agreeRequiredVesion: null,
      status: true,
      sortNumber: 2,
    },
    {
      name: 'dummyDataName3',
      fieldName: 'dummyFieldName3',
      displayEnName: 'dummyDisplayEnName3',
      displayName: 'dummyDisplayName3',
      id: '003',
      version: 2,
      url: 'https://dummy.com',
      agreeRequiredVesion: 1,
      status: true,
      sortNumber: 3,
    },
  ]);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_RULES.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_RULES.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    serviceRulesSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
