import { MailSettingStatuses } from 'components/paywall/pages/MailSetting/typed';

export const queryPaywallKey = {
  getWallList: ({ tenantId }: { tenantId: string }) => [
    'paywall/list',
    tenantId,
  ],
  getWall: ({ tenantId, wallId }: { tenantId: string; wallId: string }) => [
    'paywall/id',
    tenantId,
    wallId,
  ],
  getWallDesignList: ({
    tenantId,
    wallId,
    param
  }: {
    tenantId: string;
    wallId: string;
    param: string;
  }) => ['paywall/designList', tenantId, wallId, param],
  getWallDesign: ({
    tenantId,
    wallId,
    designId,
  }: {
    tenantId: string;
    wallId: string;
    designId: string;
  }) => ['paywall/design/id', tenantId, wallId, designId],
  getDashboard: ({ tenantId }: { tenantId: string }) => [
    'paywall/dashboard',
    tenantId,
  ],
  getCourseList: ({
    tenantId,
    wallId,
    isIncludeParent=false
  }: {
    tenantId: string;
    wallId: string;
    isIncludeParent?: boolean;
  }) => ['paywall/course/list', tenantId, wallId, isIncludeParent],
  getPromotionList: ({
    tenantId,
    courseId,
    param
  }: {
    tenantId: string;
    courseId: string;
    param: string
  }) => ['paywall/promotion/list', tenantId, courseId, param],
  getWallItemList: ({
    tenantId,
    wallId,
    filter,
    page,
    perPage,
  }: {
    tenantId: string;
    wallId: string;
    filter: string;
    page: string;
    perPage: string;
  }) => ['paywall/wallItem/list', tenantId, wallId, filter, page, perPage],
  getTerm: ({ tenantId }: { tenantId: string }) => [
    'paywall/term',
    tenantId,
  ],
  getMailSettingCommon: ({ tenantId }: { tenantId: string }) => [
    'paywall/mailSetting/common',
    tenantId,
  ],
  getMailSettingPersonal: ({ tenantId, type }: { tenantId: string, type: keyof MailSettingStatuses }) => [
    'paywall/mailSetting/personal',
    tenantId,
    type,
  ],
  getOrderList: ({ tenantId, param }: { tenantId: string, param: string }) => [
    'paywall/orderList',
    tenantId,
    param,
  ],
  getOrderDetail: ({ tenantId, id }: { tenantId: string, id: string }) => [
    'paywall/orderDetail',
    tenantId,
    id,
  ],
  getCustomerList: ({ tenantId, param }: { tenantId: string, param: string }) => [
    'paywall/customerList',
    tenantId,
    param,
  ],
  getCustomerDetail: ({ tenantId, id }: { tenantId: string, id: string }) => [
    'paywall/customerDetail',
    tenantId,
    id,
  ],
  getSubscriptionList: ({ tenantId, param }: { tenantId: string, param: string }) => [
    'paywall/subscriptionList',
    tenantId,
    param,
  ],
  getSubscriptionDetail: ({ tenantId, id }: { tenantId: string, id: string }) => [
    'paywall/subscriptionDetail',
    tenantId,
    id,
  ],
} as const;
