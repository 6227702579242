/* eslint-disable react/require-default-props */
import { HStack, StackProps } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
  isDivider?: boolean;
} & StackProps;

export const CardItem: FC<Props> = memo(({children, isDivider = true, ...rest}: Props) => {

  if (isDivider) {
    return (
      <HStack
        align="flex-start"
        spacing="16px"
        _notFirst={isDivider && {
          borderTopWidth: "1px",
          borderTopColor: Colors.GRAY_50,
          borderTopStyle: "solid",
          mt: "8px",
          pt: "8px"
        }}
        {...rest}
      >
        {children}
      </HStack>
    )
  }

  return (
    <HStack
      align="flex-start"
      spacing="16px"
      {...rest}
    >
      {children}
    </HStack>
  )
})
