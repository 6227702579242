import { Box } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FaCheck } from 'react-icons/fa6';

export const Checked = () => (
  <Box
    w="24px"
    h="24px"
    borderRadius="4px"
    bg={Colors.BLUE_30}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <FaCheck fontSize="10px" color={Colors.BLUE_00} />
  </Box>
);
