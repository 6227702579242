import { customErrorMessage, toZod } from 'admin/config/zod';
import { LogType, RuleType } from 'admin/types/userPool/rule';
import { z } from 'zod';
import { alphaNumericSchema } from '../util/index';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const ruleSchema = z.object<toZod<RuleType>>({
  id: z.string(),
  displayName: z.string(),
  displayEnName: z.string(),
  fieldName: z.string(),
  version: z.number(),
  url: z.string(),
  agreeRequiredVesion: z.optional(z.number().nullable()),
  status: z.boolean(),
  commonUse: z.boolean(),
});

export const rulesSchema = z.array(ruleSchema);

export const logSchema = z.object<toZod<LogType>>({
  id: z.string(),
  version: z.number(),
  updateContent: z.string(),
  memo: z.optional(z.string().nullable()),
  updatedTiming: z.string(),
  updaterName: z.string(),
});

export const logsSchema = z.array(logSchema);

export const createRuleSchema = z
  .object<
    toZod<
      Omit<RuleType, 'version' | 'id' | 'agreeRequiredVesion' | 'commonUse'>
    >
  >({
    displayName: z.string().optional(),
    displayEnName: z.string().optional(),
    fieldName: alphaNumericSchema.min(1, 'フィールド名は必須です'),
    url: z.string().min(1, '規約ページのURLは必須です'),
    status: z.boolean(),
  })
  .superRefine((value, ctx) => {
    if (!value.displayName && !value.displayEnName) {
      ctx.addIssue({
        code: 'custom',
        message: '日本語、外国語いずれか一つは必須となります。',
        path: ['displayName'],
        fatal: true,
      });
      ctx.addIssue({
        code: 'custom',
        message: '日本語、外国語いずれか一つは必須となります。',
        path: ['displayEnName'],
        fatal: true,
      });
    }
  });

export const editSubmitRuleSchema = createRuleSchema.and(
  z.union([
    z.object({
      canVersionUp: z.string().transform((val) => val === 'true'),
      memo: z.optional(z.string().nullable()),
    }),
    z.object({
      canVersionUp: z.string().transform((val) => val === 'false'),
    }),
  ]),
);
export const editResponseRuleSchema = createRuleSchema.and(
  z.union([
    z.object({
      canVersionUp: z.boolean(),
      memo: z.optional(z.string().nullable()),
    }),
    z.object({
      canVersionUp: z.boolean(),
    }),
  ]),
);

export const agreeFormSchema = z.object({
  message: z.string().refine((val) => val === 'agree', {
    message: '"agree"と入力してください',
  }),
});
