import {
  Box,
  DrawerBody,
  FormControl,
  FormErrorMessage,
  Heading,
  RadioGroup,
  Text,
  VStack
} from '@chakra-ui/react';
import { RhfCheckbox } from 'admin/components/form/RhfCheckbox';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfRadio } from 'admin/components/form/RhfRadio';
import { RhfTextarea } from 'admin/components/form/RhfTextarea';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors } from 'admin/define/colors';
import {
  EditRuleFormInputType,
  EditRuleFormOutputType
} from 'admin/types/userPool/rule/form';
import { ChangeEvent, FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type RuleEditFormComponentProps = {
  formId: string;
  onSubmit: (value: EditRuleFormOutputType) => void;
  isLoading: boolean;
};
export const RuleEditFormComponent: FC<RuleEditFormComponentProps> = memo(
  ({ onSubmit, formId, isLoading }) => {
    const { handleSubmit, watch, clearErrors } =
      useFormContext<EditRuleFormInputType>();
    const canVersionUpValue = watch('canVersionUp');
    const { errors } = useFormState<EditRuleFormInputType>();

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayName', 'displayEnName']);
      }
    };

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  <Box display="flex" alignItems="bottom">
                    表示名 <RequiredIcon ml="8px" />
                  </Box>
                  <Text fontSize="12px" fontWeight="normal" mt="8px" mb="16px">
                    日本語・外国語、
                    <Box as="span" color={Colors.RED_20} fontWeight="bold">
                      いずれか1つ
                    </Box>
                    は必須です。
                  </Text>
                </Heading>
                <Box bg="#F7FAFC" p="16px" borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" size="sm" mb={2}>
                      表示名（日本語）
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={Boolean(errors.displayName?.message)}
                      >
                        <Box>
                          <RhfInput<EditRuleFormInputType>
                            name="displayName"
                            bgColor={Colors.WHITE}
                            placeholder="例: プライバシーポリシー"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.displayName?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" size="sm" mb={2}>
                      表示名（外国語）
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={Boolean(errors.displayEnName?.message)}
                      >
                        <Box>
                          <RhfInput<EditRuleFormInputType>
                            name="displayEnName"
                            bgColor={Colors.WHITE}
                            placeholder="例: privacy policy"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.displayEnName?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.fieldName?.message)}>
                  <Heading as="h3" size="sm" mb={2}>
                    フィールド名
                  </Heading>
                  <Box>
                    <Text mb={2} fontSize="12px">
                      半角英数字で入力してください
                    </Text>
                    <RhfInput<EditRuleFormInputType>
                      name="fieldName"
                      bgColor={Colors.GRAY_30}
                      placeholder="placeholder"
                      isDisabled
                    />
                    <FormErrorMessage>
                      {errors.fieldName?.message}
                    </FormErrorMessage>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.canVersionUp?.message)}>
                  <Heading as="h3" size="sm" mb={2}>
                    バージョン
                  </Heading>
                  <VStack>
                    <RadioGroup defaultValue={String(canVersionUpValue)}>
                      <Box>
                        <RhfRadio<EditRuleFormInputType>
                          name="canVersionUp"
                          value="false"
                        >
                          <Box ml={1} fontSize="14px">
                            バージョンは
                            <Box as="span" fontWeight="bold" mr={1}>
                              現状のまま
                            </Box>
                            （例: 1.0.0 → 1.0.0）
                          </Box>
                        </RhfRadio>
                      </Box>
                      <Box mt={2}>
                        <RhfRadio<EditRuleFormInputType>
                          name="canVersionUp"
                          value="true"
                        >
                          <Box ml={1} fontSize="14px">
                            バージョンを
                            <Box as="span" fontWeight="bold" mr={1}>
                              上げる
                            </Box>
                            （例: 1.0.0 → 2.0.0）
                          </Box>
                        </RhfRadio>
                        {String(canVersionUpValue) === 'true' && (
                          <Box
                            w="100%"
                            mt={2}
                            mb={2}
                            ml={2}
                            bg="#F7FAFC"
                            p="16px"
                            borderWidth="1px"
                            borderRadius="4px"
                          >
                            <FormControl
                              isInvalid={Boolean(errors.memo?.message)}
                            >
                              <Heading as="h3" size="sm" mb={2}>
                                メモ（管理者のみ編集・閲覧可能）
                              </Heading>
                              <Text
                                fontSize="12px"
                                color={Colors.GRAY_00}
                                mb={2}
                              >
                                エンドユーザーには表示されません。
                                <br />
                                このメモ欄はバージョンアップの詳細を管理者が記録するためのものです。
                              </Text>
                              <Box>
                                <RhfTextarea<EditRuleFormInputType> name="memo" />
                                <FormErrorMessage>
                                  {errors.memo?.message}
                                </FormErrorMessage>
                              </Box>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    </RadioGroup>
                  </VStack>
                  <Box>
                    <FormErrorMessage>
                      {errors.canVersionUp?.message}
                    </FormErrorMessage>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.url?.message)}>
                  <Heading as="h3" size="sm" mb={2}>
                    規約ページのURL
                  </Heading>
                  <Box>
                    <RhfInput<EditRuleFormInputType>
                      name="url"
                      bgColor={Colors.WHITE}
                    />
                    <FormErrorMessage>{errors.url?.message}</FormErrorMessage>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  ステータス
                </Heading>
                <Box>
                  <RhfCheckbox<EditRuleFormInputType> name="status">
                    有効にする
                  </RhfCheckbox>
                </Box>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
      </>
    );
  },
);
