import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { editResponseRuleSchema } from 'admin/schema/idPoolConsumer/rule';
import { EditRuleFormOutputType } from 'admin/types/userPool/rule/form';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

export const editRule = async (
  data: EditRuleFormOutputType,
): Promise<EditRuleFormOutputType> => {
  // const path = userId ? `user/${userId}/` : 'user/';

  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<UserDetailType>({
  //   path,
  //   method: 'post',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  // ダミーデータ
  const dummyData = { ...data };

  const response = await getDummySuccessResponse<EditRuleFormOutputType>({
    ...dummyData,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_RULE.FAILED, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_RULE.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    editResponseRuleSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
