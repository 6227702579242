import { Page } from 'api/common/types';
import { getWallDesignList } from 'api/paywall/getWallDesignList';
import { useSearch } from 'components/opt/hooks/useSearch';
import {
  WallDesign, WallDesignListType
} from 'components/paywall/pages/WallDesignList/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * ペイウォールデザインリスト取得
 * @param {string} wallId ウォールID
 * @return {Promise<{page: Page, WallDesignList: WallDesign[]}>} ウォールデザイン一覧
 */
export const useWallDesignList = (
  wallId: string,
): {
  page: Page;
  wallDesignList: WallDesign[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();

  const response = useQuery(
    queryPaywallKey.getWallDesignList({ tenantId, wallId, param }),
    () => getWallDesignList({
      tenantId,
      wallId,
      param
    }),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const result = response.data as WallDesignListType;
  const page: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page,
    wallDesignList: results,
  };
};
